import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import AddressView from '@appchoose/address-view';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../stores/brand';
import { useUpdateSellerMutation } from '../../types/generated';
import { getDefaultOnboardingCountry } from '../../utils/address';

export type OnboardingStepReturnAddressProps = {
  goToNextStep: () => void;
};

export type OnboardingStepReturnAddressForm = Address;

export const OnboardingStepReturnAddress: React.FC<
  OnboardingStepReturnAddressProps
> = ({ goToNextStep }: OnboardingStepReturnAddressProps) => {
  const { i18n, t } = useTranslation();
  const [brand, setBrand] = useRecoilState(brandState);

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const defaultCountry = getDefaultOnboardingCountry(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    brand?.return_address?.country,
    brand?.shipping_country ?? ''
  );

  const form = useForm<OnboardingStepReturnAddressForm>({
    mode: 'onTouched',
    defaultValues: {
      name: brand?.return_address?.name ?? '',
      street: brand?.return_address?.street ?? '',
      street2: brand?.return_address?.street2 ?? '',
      bp: brand?.return_address?.bp ?? '',
      province: brand?.return_address?.province ?? '',
      city: brand?.return_address?.city ?? '',
      country: brand?.return_address?.country
        ? brand?.return_address?.country
        : (defaultCountry?.fr ?? ''),
      countryCode: brand?.return_address?.countryCode
        ? brand?.return_address?.countryCode
        : (defaultCountry?.code ?? ''),
    },
  });
  const [isFilledAddress, setIsFilledAddress] = React.useState(
    !!brand?.return_address
  );

  const onSubmit = (data: OnboardingStepReturnAddressForm) => {
    if (!brand) return;
    setBrand({
      ...brand,
      return_address: data,
    });
    updateSellerMutation({
      updateSeller: { return_address: data },
    });
    setIsFilledAddress(true);
  };

  const onEditAddress = () => {
    setIsFilledAddress(false);
  };

  return (
    <section className="w-full">
      {isFilledAddress ? (
        <>
          <div className="mt-8 sm:mt-20">
            <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
              {t('onboarding.return_address.confirm_title')}
            </h2>
          </div>
          <div className="flex flex-col items-start">
            <div className="min-w-[18.375rem] rounded-lg border border-gray-500 p-6">
              {brand?.return_address && (
                <dl>
                  <AddressView
                    label={t('address.label')}
                    address={brand?.return_address}
                    locale={i18n.language === 'fr' ? 'FR' : 'EN'}
                  />
                </dl>
              )}
              <button
                type="button"
                className="mt-10 text-sm font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
                onClick={onEditAddress}
              >
                {t('edit')}
              </button>
            </div>
            <div className="mt-8 flex sm:mt-10">
              <Button type="button" size="large" onClick={goToNextStep}>
                {t('confirm')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-8 sm:mt-20">
            <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
              {t('onboarding.return_address.title')}
            </h2>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <AddressFormFields
                googleMapsApiKey={
                  import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
                }
                locale={i18n.language}
                showCompany={true}
                showName={false}
                showPhone={false}
                autoFocus
                translations={{
                  suggestions: t('suggestions'),
                  fieldsNameValidationErrorsRequired: t(
                    'address.fields.name.validation_errors.required'
                  ),
                  fieldsNameValidationErrorsMaxLength: t(
                    'address.fields.name.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsFirstnameValidationErrorsRequired: t(
                    'address.fields.firstname.validation_errors.required'
                  ),
                  fieldsFirstnameValidationErrorsMaxLength: t(
                    'address.fields.firstname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsLastnameValidationErrorsRequired: t(
                    'address.fields.lastname.validation_errors.required'
                  ),
                  fieldsLastnameValidationErrorsMaxLength: t(
                    'address.fields.lastname.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsPhoneValidationErrorsRequired: t(
                    'address.fields.phone.validation_errors.required'
                  ),
                  fieldsPhoneValidationErrorsMaxLength: t(
                    'address.fields.phone.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreetValidationErrorsRequired: t(
                    'address.fields.street.validation_errors.required'
                  ),
                  fieldsStreetValidationErrorsMaxLength: t(
                    'address.fields.street.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsStreet2ValidationErrorsMaxLength: t(
                    'address.fields.street2.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsBpValidationErrorsRequired: t(
                    'address.fields.bp.validation_errors.required'
                  ),
                  fieldsBpValidationErrorsMaxLength: t(
                    'address.fields.bp.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCityValidationErrorsRequired: t(
                    'address.fields.city.validation_errors.required'
                  ),
                  fieldsCityValidationErrorsMaxLength: t(
                    'address.fields.city.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsProvinceValidationErrorsRequired: t(
                    'address.fields.province.validation_errors.required'
                  ),
                  fieldsProvinceValidationErrorsMaxLength: t(
                    'address.fields.province.validation_errors.maxLength',
                    {
                      maxLength: '200',
                    }
                  ),
                  fieldsCountryValidationErrorsRequired: t(
                    'address.fields.country.validation_errors.required'
                  ),
                  fieldsNameLabel: t('address.fields.name.label_return'),
                  fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                  fieldsFirstnameLabel: t('address.fields.firstname.label'),
                  fieldsFirstnamePlaceholder: t(
                    'address.fields.firstname.placeholder'
                  ),
                  fieldsLastnameLabel: t('address.fields.lastname.label'),
                  fieldsLastnamePlaceholder: t(
                    'address.fields.lastname.placeholder'
                  ),
                  fieldsPhoneLabel: t('address.fields.phone.label'),
                  fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                  fieldsStreetLabel: t('address.fields.street.label'),
                  fieldsStreetPlaceholder: t(
                    'address.fields.street.placeholder'
                  ),
                  fieldsStreet2Label: t('address.fields.street2.label'),
                  fieldsStreet2Placeholder: t(
                    'address.fields.street2.placeholder'
                  ),
                  fieldsBpLabel: t('address.fields.bp.label'),
                  fieldsBpPlaceholder: '',
                  fieldsCityLabel: t('address.fields.city.label'),
                  fieldsCityPlaceholder: '',
                  fieldsProvinceLabel: t('address.fields.province.label'),
                  fieldsProvincePlaceholder: '',
                  fieldsProvinceEmptyField: t(
                    'address.fields.province.empty_field'
                  ),
                  fieldsCountryLabel: t('address.fields.country.label'),
                  fieldsCountryPlaceholder: t(
                    'address.fields.country.placeholder'
                  ),
                  fieldsCountryPlaceholderSearch: t(
                    'address.fields.country.placeholder_search'
                  ),
                  fieldsCountryEmptyField: t(
                    'address.fields.country.empty_field'
                  ),
                  fieldsCountryNoResults: t(
                    'address.fields.country.no_results'
                  ),
                }}
              />
              <div className="mt-8 flex sm:mt-10">
                <Button type="submit" size="large">
                  {t('continue')}
                </Button>
              </div>
            </form>
          </Form>
        </>
      )}
    </section>
  );
};

OnboardingStepReturnAddress.displayName = 'OnboardingStepReturnAddress';
