import React, { useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tooltip from '@appchoose/tooltip';
import { Instance, Props } from 'tippy.js';

import { otherDeliveryImage, otherShippingImage } from '../../types/services';
import {
  AddService,
  AddServiceForm,
  AddServiceRef,
} from '../add-service/add-service';
import { OnboardingStepShippingDeliveryServiceForm } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import { OnboardingStepShippingShippingServiceForm } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-shipping-service';
import { ServiceView } from '../service-view/ServiceView';

type EditServicesProps = {
  type: 'delivery' | 'shipping';
  singleValue?: boolean;
};

export const EditServices: React.FC<EditServicesProps> = ({
  type,
  singleValue,
}: EditServicesProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    register,
    trigger,
    setValue,
    watch,
  } = useFormContext<
    | OnboardingStepShippingDeliveryServiceForm
    | OnboardingStepShippingShippingServiceForm
  >();
  const { fields, append } = useFieldArray({
    control,
    name: 'services',
  });

  const [tooltip, setTooltip] = useState<Instance<Props> | null>(null);
  const addServiceRef = useRef<AddServiceRef | null>(null);

  const services = watch('services');

  const otherServiceImage =
    type === 'delivery' ? otherDeliveryImage : otherShippingImage;

  const appendServiceName = (data: AddServiceForm) => {
    if (singleValue) deselectAll();
    append({
      type: 'custom',
      text: data.service,
      selected: true,
      image: otherServiceImage,
    });
    if (tooltip) tooltip.hide();
  };

  const ErrorMessage =
    errors.services
      ?.filter?.((s) => s !== undefined)
      .map((s) => s?.selected?.type)
      .find((t) => t === 'atLeastOne') &&
    t('onboarding.shipping.services.validation_errors.at_least_one');

  // Génère la liste de tous les `services.${number}.selected`
  const selectedServicesFields = Array.from(
    Array(getValues('services').length).keys()
  ).map(
    (value) => `services.${value}.selected`
  ) as `services.${number}.selected`[];

  const deselectAll = () => {
    getValues('services').forEach((value, index) => {
      setValue(`services.${index}.selected`, false);
    });
  };

  const deselectAllAndSelectSingle = (indexToSelect: number) => {
    deselectAll();
    setValue(`services.${indexToSelect}.selected`, true);
  };

  return (
    <>
      <div className="flex flex-1 flex-wrap gap-3.5">
        {fields.map((field, index) => {
          const registerSelectedServices = {
            ...register(`services.${index}.selected`, {
              validate: {
                atLeastOne: () =>
                  getValues('services').filter((s) => s.selected).length > 0,
              },
            }),
          };
          return (
            <label key={field.id}>
              <ServiceView
                service={field}
                isSelected={services[index]?.selected}
              >
                <input
                  {...registerSelectedServices}
                  onChange={(e) => {
                    if (singleValue) deselectAllAndSelectSingle(index);
                    registerSelectedServices.onChange(e);
                    trigger(selectedServicesFields);
                  }}
                  type="checkbox"
                  defaultChecked={field.selected}
                  className="sr-only"
                />
              </ServiceView>
            </label>
          );
        })}
        <div className="flex size-20 cursor-pointer items-center justify-center rounded-lg border border-gray-500 sm:size-[8.75rem]">
          <Tooltip
            interactive
            trigger={'click'}
            placement="bottom"
            theme="light"
            content={
              <AddService
                ref={addServiceRef}
                getValues={getValues}
                onSubmit={appendServiceName}
              />
            }
            onCreate={(instance) => setTooltip(instance)}
            onShow={() => addServiceRef.current?.initForm()}
            onShown={() => addServiceRef.current?.setInputFocus()}
          >
            <div className="flex size-14 flex-col items-center justify-center sm:size-25">
              <img
                src={otherServiceImage}
                alt=""
                className="size-14 object-contain sm:size-20"
              />
              <span className="text-xs font-semibold text-gray-900">
                {t('other')}
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="mt-2 text-xs text-red-600">{ErrorMessage}</div>
    </>
  );
};
