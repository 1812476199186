import { useRecoilValue } from 'recoil';

import { activeSaleIdState } from '../../stores/sales';
import { useInvoicesQuery } from '../../types/generated-new';

export const useInvoices = () => {
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const invoices = useInvoicesQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      select: (data) => data?.invoices ?? [],
    }
  );

  return invoices;
};
