import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import { isAfter } from 'date-fns';

import { activeSaleState } from '../../stores/sales';
import { amDefaultContact, findContact } from '../../types/choose-contact';
import { SaleStatus } from '../../types/generated-new';
import { AmContact } from './am-contact';
import { useBillingState } from './use-billing-state';

export const InvoiceLockedUnlockInvoice: React.FC = () => {
  const { t } = useTranslation();
  const { data: billingState } = useBillingState();

  const activeSale = useRecoilValue(activeSaleState);

  if (!billingState) {
    return null;
  }

  const amContact = findContact(
    activeSale?.logistics_manager ?? undefined,
    amDefaultContact
  );

  const conditions = [
    {
      key: 'sale_is_over',
      label: t('invoice.invoice_locked.unlock_invoice.sale_is_over'),
      isMet: billingState.saleStatus === SaleStatus.Ended,
    },
    {
      key: 'orders_delivered',
      label: t('invoice.invoice_locked.unlock_invoice.all_orders_delivered'),
      isMet: billingState.allOrdersDelivered,
    },
    {
      key: 'withdrawal_period_is_over',
      label: t(
        'invoice.invoice_locked.unlock_invoice.withdrawal_period_is_over'
      ),
      isMet:
        billingState.editableAt !== null &&
        billingState.editableAt !== undefined &&
        isAfter(new Date(), new Date(billingState.editableAt)),
    },
    {
      key: 'returns_validated',
      label: t('invoice.invoice_locked.unlock_invoice.all_returns_refunded'),
      isMet: billingState.allReturnsValidated,
    },
    {
      key: 'claims_fulfilled',
      label: t('invoice.invoice_locked.unlock_invoice.all_claims_processed'),
      isMet: billingState.allClaimsFulfilled,
    },
  ];

  return (
    <div className="flex flex-col gap-6 rounded border border-gray-100 p-6 md:flex-row md:gap-12">
      <div className="flex flex-1 flex-col gap-4 text-gray-700">
        <div className="text-sm font-semibold">
          {t(
            'invoice.invoice_locked.unlock_invoice.requirements_to_unlock_invoice'
          )}
        </div>
        <ul className="flex list-inside list-disc flex-col gap-2">
          {conditions.map((condition) => (
            <li key={condition.key} className="flex items-center gap-2">
              <span>
                {condition.isMet ? (
                  <Icon
                    icon="check"
                    className="size-[1.125rem] text-green-600"
                  />
                ) : (
                  <Icon icon="close" className="size-[1.125rem] text-red-600" />
                )}
              </span>
              <span className="text-sm leading-5.5">{condition.label}</span>
            </li>
          ))}
        </ul>
      </div>
      {amContact ? (
        <>
          <div className="flex h-px w-full border-none bg-gray-100 md:h-auto md:w-px" />
          <AmContact contact={amContact} />
        </>
      ) : null}
    </div>
  );
};
