import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';

const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

type CountdownTimerItemProps = {
  value: number;
  variant: 'warning' | 'success';
};

const CountdownTimerItem: React.FC<CountdownTimerItemProps> = ({
  value,
  variant,
}) => {
  const digits = Array.from({ length: 10 }, (_, i) => i);
  return (
    <div
      className={cn('rounded border bg-white', {
        'border-[#F4E9D7]': variant === 'warning',
        'border-[#B8E1CB]': variant === 'success',
      })}
    >
      <div
        className="px-1.5 text-xl font-medium"
        style={{
          transform: `translateY(-${value}0%`,
          transitionDuration: `${value === 0 ? 200 : 500}ms`,
        }}
      >
        {digits.map((digit) => (
          <span
            key={digit}
            className="relative flex h-11 items-center justify-center"
          >
            {digit}
          </span>
        ))}
      </div>
    </div>
  );
};

type CountdownProps = {
  targetDate: string;
  variant: 'warning' | 'success';
};

export const Countdown: React.FC<CountdownProps> = ({
  targetDate,
  variant,
}) => {
  const { t } = useTranslation();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <div className="flex">
      {days > 0 ? (
        <>
          <div className="relative flex flex-col gap-0.5">
            <div className="flex h-11 flex-row gap-1 overflow-hidden">
              <CountdownTimerItem
                value={Number(days.toString().padStart(2, '0')[0])}
                variant={variant}
              />
              <CountdownTimerItem
                value={Number(days.toString().padStart(2, '0')[1])}
                variant={variant}
              />
            </div>
            <p className="text-center text-[9px] font-semibold uppercase tracking-[0.04031rem] text-gray-700">
              {t('days')}
            </p>
          </div>
          <span className="px-1 text-xl leading-[2.75rem] text-gray-500">
            :
          </span>
        </>
      ) : null}

      <div className="relative flex flex-col gap-0.5">
        <div className="flex h-11 flex-row gap-1 overflow-hidden">
          <CountdownTimerItem
            value={Number(hours.toString().padStart(2, '0')[0])}
            variant={variant}
          />
          <CountdownTimerItem
            value={Number(hours.toString().padStart(2, '0')[1])}
            variant={variant}
          />
        </div>
        <p className="text-center text-[9px] font-semibold uppercase tracking-[0.04031rem] text-gray-700">
          {t('hours')}
        </p>
      </div>

      <span className="px-1 text-xl leading-[2.75rem] text-gray-500">:</span>

      <div className="relative flex flex-col gap-0.5">
        <div className="flex h-11 flex-row gap-1 overflow-hidden">
          <CountdownTimerItem
            value={Number(minutes.toString().padStart(2, '0')[0])}
            variant={variant}
          />
          <CountdownTimerItem
            value={Number(minutes.toString().padStart(2, '0')[1])}
            variant={variant}
          />
        </div>
        <p className="text-center text-[9px] font-semibold uppercase tracking-[0.04031rem] text-gray-700">
          {t('minutes')}
        </p>
      </div>

      <span className="px-1 text-xl leading-[2.75rem] text-gray-500">:</span>

      <div className="relative flex flex-col gap-0.5">
        <div className="flex h-11 flex-row gap-1 overflow-hidden">
          <CountdownTimerItem
            value={Number(seconds.toString().padStart(2, '0')[0])}
            variant={variant}
          />
          <CountdownTimerItem
            value={Number(seconds.toString().padStart(2, '0')[1])}
            variant={variant}
          />
        </div>
        <p className="text-center text-[9px] font-semibold uppercase tracking-[0.04031rem] text-gray-700">
          {t('seconds')}
        </p>
      </div>
    </div>
  );
};

export default Countdown;
