import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import {
  BrandRole,
  useDetachBrandUserMutation,
  useMembersQuery,
  useSetUserRoleMutation,
} from '../../types/generated-new';
import { isScopeAuthorized } from '../../utils/auth';
import { InviteLink } from './invite-link';
import { MemberView } from './member-view';

type SettingsMembersProps = {
  onPostSave: () => void;
};

export type SettingsMembersFormData = {
  firstName: string;
  lastName: string;
};

export const SettingsMembers: React.FC<SettingsMembersProps> = () => {
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    data: membersData,
    isLoading: loadingMembers,
    error: errorMembers,
    refetch: refetchMembers,
  } = useMembersQuery(undefined, {
    refetchOnWindowFocus: false,
    select: (data) => data.members,
  });

  const { mutateAsync: detachBrandUserMutation } = useDetachBrandUserMutation();
  const { mutateAsync: setUserRoleMutation } = useSetUserRoleMutation();

  const isInternalUser = !!user?.['https://appchoose.io/claims/isInternalUser'];
  const isUserAdmin =
    isInternalUser ||
    membersData?.find((brandUser) => brandUser.email === user?.email)?.role ===
      'ADMIN';

  const admins =
    membersData
      ?.filter((user) => user.role === BrandRole.Admin.toString())
      .sort((a, b) => a.firstname.localeCompare(b.firstname)) ?? [];
  const members =
    membersData
      ?.filter((user) => user.role === BrandRole.Member.toString())
      .sort((a, b) => a.firstname.localeCompare(b.firstname)) ?? [];

  async function modifyStatus(userId: string, role: BrandRole) {
    try {
      await setUserRoleMutation({
        userId,
        role,
      });

      queryClient.invalidateQueries({
        queryKey: ['members', undefined],
      });
    } catch {
      toast.error(t('settings.member_tabs.error_status_mutation'));
    }
  }

  async function handleUserDelete(userId: string) {
    try {
      await detachBrandUserMutation({
        userId,
      });

      queryClient.invalidateQueries({
        queryKey: ['members', undefined],
      });
    } catch {
      toast.error(t('settings.member_tabs.error_delete_member'));
    }
  }

  return (
    <div className="max-w-2.5xl pb-6">
      {isUserAdmin
        ? isScopeAuthorized(
            user,
            'scope.brand.settings.members.invite.view'
          ) && <InviteLink />
        : null}

      <div className="divide-y divide-gray-300">
        <div className="flex justify-between pb-5">
          <div className="text-xs font-semibold uppercase text-gray-700">
            {(membersData?.length ?? 0) > 1
              ? t('settings.member_tabs.members')
              : t('settings.member_tabs.member')}
            <span className="ml-1 font-semibold text-gray-500">{`(${
              membersData?.length ?? 0
            })`}</span>
          </div>
          <div className="text-xs font-semibold uppercase text-gray-700">
            {t('settings.member_tabs.access_level')}
          </div>
        </div>

        {errorMembers ? (
          <div className="flex flex-col items-center p-10">
            <div className="mb-6 max-w-md text-center text-sm text-gray-700">
              {t('settings.member_tabs.error_loading_members')}
            </div>
            <Button
              appearance="primary"
              type="button"
              onClick={() => refetchMembers()}
            >
              {t('settings.member_tabs.retry')}
            </Button>
          </div>
        ) : loadingMembers ? (
          <div className="flex w-full justify-center">
            <Loader className="mt-4 size-8" />
          </div>
        ) : (
          admins
            .concat(members)
            .map((member) => (
              <MemberView
                brandHasMoreThanOneAdmin={admins.length > 1}
                isMemberActive={member.email === user?.email}
                isUserAdmin={isUserAdmin}
                handleUserDelete={handleUserDelete}
                modifyStatus={modifyStatus}
                member={member}
                key={member.userId}
              />
            ))
        )}
      </div>
    </div>
  );
};

SettingsMembers.displayName = 'SettingsMembers';
