import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

import { ChooseContact } from '../../types/choose-contact';

type AmContactProps = {
  contact: ChooseContact;
};

export const AmContact: React.FC<AmContactProps> = ({ contact }) => {
  const { t } = useTranslation();
  const [emailClipboardState, copyEmailToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = emailClipboardState;
    if (value) {
      toast.success(t('clipboard.email_copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [emailClipboardState, t]);

  return (
    <div className="flex flex-col justify-center gap-6">
      <div className="flex items-center gap-4">
        <img
          src={`https://public.choose.app/avatars/80x80/${contact.avatar}-80x80.png`}
          srcSet={`https://public.choose.app/avatars/80x80/${contact.avatar}-80x80@2x.png 2x,
               https://public.choose.app/avatars/80x80/${contact.avatar}-80x80@3x.png 3x`}
          className="inline-block size-14 rounded-full ring-1 ring-gray-100"
          alt={t('invoice.invoice_locked.am_contact.profile_picture', {
            name: contact.firstName,
          })}
        />
        <div className="flex flex-col gap-[0.12rem]">
          <div className="font-bold leading-5.5 text-gray-900">
            {t('invoice.invoice_locked.am_contact.account_manager', {
              name: contact.firstName,
            })}
          </div>
          <div className="max-w-[13.875rem] text-xs leading-[1.125rem] text-gray-700">
            {t('invoice.invoice_locked.am_contact.need_help')}
          </div>
        </div>
      </div>

      <button
        onClick={() => copyEmailToClipboard(contact.email)}
        type="button"
        className="flex place-content-center items-center gap-1 rounded border border-gray-100 bg-[#FBFBFB] p-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]"
      >
        <span>{contact.email}</span>

        <Icon icon="copy" className="size-3.5" />
      </button>
    </div>
  );
};
