type OpenProps = React.ComponentPropsWithoutRef<'svg'>;

export const Open: React.FC<OpenProps> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 3C19.4477 3 19 3.44772 19 4C19 4.01917 19.0005 4.03822 19.0016 4.05712M20 3C20.5523 3 21 3.44772 21 4C21 4.01917 20.9995 4.03822 20.9984 4.05712M20 3L19.0016 4.05712M20 3L20.9984 4.05712M11.5 12H5C3.89543 12 3 12.8954 3 14V34C3 35.1046 3.89543 36 5 36H35C36.1046 36 37 35.1046 37 34V14C37 12.8954 36.1046 12 35 12H28.5M11.5 12H28.5M11.5 12L19.0016 4.05712M28.5 12L20.9984 4.05712M19.0016 4.05712C19.0312 4.58283 19.4669 5 20 5C20.5331 5 20.9688 4.58283 20.9984 4.05712"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4041 27C11.8569 27 12.625 25.3775 12.625 24.0254C12.625 22.6732 11.6231 21 10.4041 21C9.18511 21 8.25 22.4203 8.25 24.0254C8.25 25.6304 8.95134 27 10.4041 27Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.875 27H21.375V23.9119M24.7396 21H21.375V23.9119M21.375 23.9119H24.2756"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.55 27C14.55 27.3866 14.8634 27.7 15.25 27.7C15.6366 27.7 15.95 27.3866 15.95 27H14.55ZM15.25 21V20.3C14.8634 20.3 14.55 20.6134 14.55 21H15.25ZM15.25 21.7H17.25V20.3H15.25V21.7ZM15.95 27V24H14.55V27H15.95ZM15.95 24V21H14.55V24H15.95ZM17.25 23.3H15.25V24.7H17.25V23.3ZM17.25 24.7C18.465 24.7 19.45 23.715 19.45 22.5H18.05C18.05 22.9418 17.6918 23.3 17.25 23.3V24.7ZM17.25 21.7C17.6918 21.7 18.05 22.0582 18.05 22.5H19.45C19.45 21.285 18.465 20.3 17.25 20.3V21.7Z"
        fill="currentColor"
      />
      <path
        d="M27.5 27V21.0535L31 27V21"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
