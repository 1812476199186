import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import { TFunction } from 'i18next';

import { OrderTagReship } from '../../types/generated-new';
import { OrderBadge } from './order-badge';

export const getOrderReshipLabelFromTag = (
  tag: OrderTagReship,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagReship.Reshipped:
      return t('order.status.reship.reship');
  }
};

export const OrderReshipBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagReship;
}> = ({ tag, isCancelled }) => {
  const { t } = useTranslation();

  if (tag === OrderTagReship.None) return null;

  return (
    <OrderBadge>
      <div className="flex items-center gap-x-1">
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderReshipLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
