import { useTranslation } from 'react-i18next';

import { Open } from '../../components/icons/open';

export const InvoiceLockedSaleOngoing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="flex flex-col gap-6 rounded border border-gray-100 bg-[#FBFBFB] p-6 md:flex-row md:items-center">
      <Open className="size-10 shrink-0 text-gray-900" />
      <div className="flex flex-col gap-1 text-gray-700">
        <h4 className="text-sm font-bold">
          {t('invoice.invoice_locked.sale_ongoing.sale_in_progress')}
        </h4>
        <p className="text-sm leading-5.5">
          {t(
            'invoice.invoice_locked.sale_ongoing.billing_available_fulfilled_requirements'
          )}
        </p>
      </div>
    </section>
  );
};
