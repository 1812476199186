import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { amDefaultContact, findContact } from '../../types/choose-contact';
import { InvoiceStatus, InvoiceType } from '../../types/generated-new';
import { transformPrice } from '../../utils/currency';
import { formatFullDate } from '../../utils/date';
import { formatPercentage } from '../../utils/number';
import { AmContact } from './am-contact';
import { useBilling } from './use-billing';
import { useInvoices } from './use-invoices';

export const InvoiceLockedUnlockClassicDownPayment: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { data: billing } = useBilling();
  const { data: invoices } = useInvoices();

  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const amContact = findContact(
    activeSale?.logistics_manager ?? undefined,
    amDefaultContact
  );

  if (
    !billing?.down_payment?.__typename ||
    billing.down_payment.__typename !== 'DownPaymentClassic' ||
    billing.down_payment.rules.every((rule) => rule.isValidated) ||
    invoices
      ?.filter((invoice) => invoice.type === InvoiceType.DownPayment)
      .some(
        (invoice) =>
          invoice.status === InvoiceStatus.Validated ||
          invoice.status === InvoiceStatus.ToBePaid ||
          invoice.status === InvoiceStatus.Paid
      )
  ) {
    return null;
  }

  const conditions = billing.down_payment.rules
    .map((rule) => {
      if (rule.__typename === 'ActivationDateRule') {
        return {
          key: 'activation_date',
          label: t(
            'invoice.invoice_locked.unlock_classic_down_payment.activation_date',
            {
              date: formatFullDate(
                new Date(rule.activationDate),
                i18n.language === 'fr' ? 'fr' : 'en',
                brand?.timezone ?? undefined
              ),
            }
          ),
          isMet: rule.isValidated,
        };
      }
      if (rule.__typename === 'FulfilledOrderPercentageRule') {
        return {
          key: 'fulfilled_order_percentage',
          label: t(
            'invoice.invoice_locked.unlock_classic_down_payment.fulfilled_order_percentage',
            {
              amount: formatPercentage(rule.targetValue / 100, i18n.language),
            }
          ),
          isMet: rule.isValidated,
        };
      }
      if (rule.__typename === 'MinimumAmountInCentsRule') {
        return {
          key: 'minimum_amount_in_cents',
          label: t(
            'invoice.invoice_locked.unlock_classic_down_payment.minimum_amount_in_cents',
            {
              amount: transformPrice(
                rule.targetValue,
                i18n.language === 'fr' ? 'fr' : 'en',
                activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
              ),
            }
          ),
          isMet: rule.isValidated,
        };
      }
    })
    .filter((rule) => !!rule);

  return (
    <div className="flex flex-col gap-6 rounded border border-gray-100 p-6 md:flex-row md:gap-12">
      <div className="flex flex-1 flex-col gap-4 text-gray-700">
        <div className="whitespace-pre-line text-sm font-semibold">
          {t(
            'invoice.invoice_locked.unlock_classic_down_payment.eligible_to_down_payment',
            {
              amount: formatPercentage(
                billing.down_payment.rate / 100,
                i18n.language
              ),
            }
          )}
        </div>
        <ul className="flex list-inside list-disc flex-col gap-2">
          {conditions.map((condition) => (
            <li key={condition.key} className="flex items-center gap-2">
              <span>
                {condition.isMet ? (
                  <Icon
                    icon="check"
                    className="size-[1.125rem] text-green-600"
                  />
                ) : (
                  <Icon icon="close" className="size-[1.125rem] text-red-600" />
                )}
              </span>
              <span className="text-sm leading-5.5">{condition.label}</span>
            </li>
          ))}
        </ul>
      </div>
      {amContact ? (
        <>
          <div className="flex h-px w-full border-none bg-gray-100 md:h-auto md:w-px" />
          <AmContact contact={amContact} />
        </>
      ) : null}
    </div>
  );
};
