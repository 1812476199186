import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  InvoiceStatus,
  InvoiceType,
  SaleStatus,
} from '../../types/generated-new.js';
import { DownPaymentSummary } from './down-payment-summary.jsx';
import { DownPaymentUpload } from './down-payment-upload.jsx';
import { InvoiceLockedSaleEndedNeedActions } from './invoice-locked-sale-ended-need-actions';
import { InvoiceLockedSaleEndedNeedInternalActions } from './invoice-locked-sale-ended-need-internal-actions';
import { InvoiceLockedSaleEndedWaitingWithdrawal } from './invoice-locked-sale-ended-waiting-withdrawal';
import { InvoiceLockedSaleOngoing } from './invoice-locked-sale-ongoing';
import { InvoiceLockedSaleUpcoming } from './invoice-locked-sale-upcoming';
import { InvoiceLockedUnlockClassicDownPayment } from './invoice-locked-unlock-classic-down-payment.jsx';
import { InvoiceLockedUnlockExceptionalDownPayment } from './invoice-locked-unlock-exceptional-down-payment.jsx';
import { InvoiceLockedUnlockInvoice } from './invoice-locked-unlock-invoice';
import { useBillingState } from './use-billing-state';
import { useBilling } from './use-billing.js';
import { useInvoices } from './use-invoices.js';

const SaleStatusBanner: React.FC = () => {
  const { data: billingState } = useBillingState();

  if (!billingState) {
    return null;
  }

  if (billingState.saleStatus === SaleStatus.Upcoming) {
    return <InvoiceLockedSaleUpcoming />;
  }

  if (billingState.saleStatus === SaleStatus.Ongoing) {
    return <InvoiceLockedSaleOngoing />;
  }

  const unlockOrderConditionsAreMet =
    billingState.allClaimsFulfilled &&
    billingState.allOrdersDelivered &&
    billingState.allReturnsValidated;

  if (
    billingState.saleStatus === SaleStatus.Ended &&
    !unlockOrderConditionsAreMet
  ) {
    return <InvoiceLockedSaleEndedNeedActions />;
  }

  if (
    billingState.saleStatus === SaleStatus.Ended &&
    unlockOrderConditionsAreMet &&
    billingState.editableAt
  ) {
    return <InvoiceLockedSaleEndedWaitingWithdrawal />;
  }

  if (
    billingState.saleStatus === SaleStatus.Ended &&
    unlockOrderConditionsAreMet &&
    !billingState.editableAt &&
    billingState.errors
  ) {
    return <InvoiceLockedSaleEndedNeedInternalActions />;
  }
};

type InvoiceLockedProps = {
  isSubjectVat: boolean;
};

export const InvoiceLocked: React.FC<InvoiceLockedProps> = ({
  isSubjectVat,
}) => {
  const { t } = useTranslation();
  const { data: billing } = useBilling();
  const { data: billingState } = useBillingState();
  const { data: invoices } = useInvoices();

  if (!billingState || !invoices) {
    return null;
  }

  const downPayments =
    invoices?.filter((invoice) => invoice.type === InvoiceType.DownPayment) ??
    [];
  const showDownPayment =
    billing?.down_payment &&
    (downPayments.length === 0 ||
      downPayments.every(
        (invoice) =>
          invoice.status !== InvoiceStatus.Validated &&
          invoice.status !== InvoiceStatus.ToBePaid &&
          invoice.status !== InvoiceStatus.Paid
      ));

  return (
    <>
      <div className="flex flex-col gap-6">
        {!showDownPayment &&
        ((billing?.payments.length ?? 0) > 0 || (invoices?.length ?? 0) > 0) ? (
          <h3 className="text-2xl font-bold text-gray-900">
            {t('invoice.rest_of_the_bill')}
          </h3>
        ) : null}
        {showDownPayment ? (
          <h3 className="text-2xl font-bold text-gray-900">
            {t('invoice.down_payment')}
          </h3>
        ) : null}
        {!showDownPayment ? <SaleStatusBanner /> : null}
        {!showDownPayment ? <InvoiceLockedUnlockInvoice /> : null}
        <InvoiceLockedUnlockClassicDownPayment />
        <InvoiceLockedUnlockExceptionalDownPayment />
        <DownPaymentUpload isSubjectVat={isSubjectVat} />
      </div>
      <DownPaymentSummary isSubjectVat={isSubjectVat} />
    </>
  );
};
