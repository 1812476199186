import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import { addDays, isAfter } from 'date-fns';

import { activeSaleState } from '../../stores/sales';
import Countdown from '../countdown/countdown';
import { Checklist } from '../icons/checklist';
import { Chrono } from '../icons/chrono';

export const SaleAvailabilityBanner: React.FC = () => {
  const { i18n, t } = useTranslation();

  const activeSale = useRecoilValue(activeSaleState);

  if (!activeSale) return null;

  const saleAvailabilityDate = new Date(
    activeSale.start_at ?? ''
  ).toLocaleDateString(i18n.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const isLessThanOneDayBeforeSale = isAfter(
    addDays(new Date(), 1),
    new Date(activeSale.start_at ?? '')
  );

  return (
    <section
      className={cn(
        'flex flex-col justify-between gap-6 rounded-lg border p-6 pr-10 sm:flex-row',
        {
          'border-[#F4E9D7] bg-beige-300': !isLessThanOneDayBeforeSale,
          'border-[#B8E1CB] bg-[#E7F5EE]': isLessThanOneDayBeforeSale,
        }
      )}
    >
      <div className="flex flex-col gap-6 sm:flex-row sm:items-center">
        {isLessThanOneDayBeforeSale ? (
          <Chrono className="size-12 text-gray-700" />
        ) : (
          <Checklist className="size-12 text-gray-700" />
        )}
        <div className="flex flex-col gap-1">
          <div className="text-sm text-[#767A7C]">
            {t('home.sale_availability_banner.sale_availability')}
          </div>
          <div className="text-2xl font-semibold text-gray-900">
            {saleAvailabilityDate}
          </div>
        </div>
      </div>
      {activeSale?.start_at ? (
        <Countdown
          targetDate={activeSale?.start_at}
          variant={isLessThanOneDayBeforeSale ? 'success' : 'warning'}
        />
      ) : null}
    </section>
  );
};

SaleAvailabilityBanner.displayName = 'SaleAvailabilityBanner';
