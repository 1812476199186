import { useTranslation } from 'react-i18next';

import { Confetti } from '../../components/icons/confetti';
import { useBilling } from './use-billing';

type InvoiceFullyPaidBannerProps = {
  isSubjectVat: boolean;
};

export const InvoiceFullyPaidBanner: React.FC<InvoiceFullyPaidBannerProps> = ({
  isSubjectVat,
}) => {
  const { t } = useTranslation();
  const { data: billing } = useBilling();

  const remainingAmount = isSubjectVat
    ? (billing?.total_remaining_ttc ?? 0)
    : (billing?.total_remaining_ht ?? 0);

  if (!billing || remainingAmount > 0) {
    return null;
  }

  return (
    <div className="flex flex-col justify-between space-x-6 rounded border border-[#C6E7D5] bg-[#ECF7F1] p-6 md:flex-row">
      <div className="flex w-full items-start space-x-4 pb-4 md:pb-0">
        <div className="rounded-full text-green-900">
          <Confetti className="text-green-600" />
        </div>
        <div className="w-full space-y-1">
          <div className="text-sm font-semibold text-gray-700">
            {t('invoice.invoice_information.your_operation_fully_paid')}
          </div>
          <p className="text-sm text-gray-700">
            {t('invoice.invoice_information.see_you_soon')}
          </p>
        </div>
      </div>
    </div>
  );
};
