import { useTranslation } from 'react-i18next';

import { Clock } from '../../components/icons/clock';

export const SaleNotStarted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex max-w-[55rem] flex-col justify-between space-x-6 rounded border border-gray-200 bg-gray-50 p-6 md:flex-row">
      <div className="flex w-full items-center space-x-4 pb-4 md:pb-0">
        <Clock className="size-10" />

        <div className="flex w-full flex-col gap-1">
          <div className="text-sm font-semibold text-gray-700">
            {t('orders.sale_not_started.sale_not_started')}
          </div>
          <p className="text-sm text-gray-700">
            {t('orders.sale_not_started.orders_will_appear_here')}
          </p>
        </div>
      </div>
    </div>
  );
};
