import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Closed } from '../../components/icons/closed';
import { brandState } from '../../stores/brand';
import { formatFullDate } from '../../utils/date';
import { useBillingState } from './use-billing-state';

export const InvoiceLockedSaleEndedWaitingWithdrawal: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { data: billingState } = useBillingState();

  const brand = useRecoilValue(brandState);

  return (
    <section className="flex flex-col gap-6 rounded border border-gray-100 bg-[#FBFBFB] p-6 md:flex-row md:items-center md:gap-8">
      <div className="flex flex-col gap-6 md:flex-row md:items-center">
        <Closed className="size-10 shrink-0 text-gray-900" />
        <div className="flex flex-col gap-1 text-gray-700">
          <h4 className="text-sm font-bold">
            {t(
              'invoice.invoice_locked.sale_ended_waiting_withdrawal.done_all_the_work'
            )}
          </h4>
          <p className="text-sm leading-5.5">
            {t(
              'invoice.invoice_locked.sale_ended_waiting_withdrawal.invoice_available_after_withdrawal_period'
            )}
          </p>
        </div>
      </div>
      {billingState?.editableAt ? (
        <div className="flex shrink-0 flex-col gap-1 rounded border border-gray-100 bg-white px-6 py-4 text-center">
          <div className="text-xs font-semibold uppercase text-gray-500">
            {t(
              'invoice.invoice_locked.sale_ended_waiting_withdrawal.unlocking_estimate'
            )}
          </div>
          <div className="text-sm font-semibold text-gray-900">
            {formatFullDate(
              new Date(billingState.editableAt),
              i18n.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
};
