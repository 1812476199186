import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Illustration from '@appchoose/illustration';
import Input from '@appchoose/input';

import shopify from '../../assets/img/img-logo-shopify.png';
import { Brand, brandState } from '../../stores/brand';
import { useUpdateSellerMutation } from '../../types/generated';
import { getUrlPattern } from '../../utils/utils';

export type OnboardingStepShopifyProps = {
  goToNextStep: () => void;
};

type OnboardingStepShopifyForm = {
  shopifyUrl: string;
};

export const OnboardingStepShopify: React.FC<OnboardingStepShopifyProps> = ({
  goToNextStep,
}: OnboardingStepShopifyProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const [subStep, setSubStep] = useState(0);
  const form = useForm<OnboardingStepShopifyForm>({
    mode: 'onTouched',
    defaultValues: { shopifyUrl: brand?.shopify_url ?? '' },
  });
  const urlPattern = getUrlPattern();

  const onUpdate = (
    data: Pick<NonNullable<Brand>, 'shopify_url'> & { has_shopify: boolean }
  ) => {
    if (!brand) return;
    updateSellerMutation({ updateSeller: data });
    setBrand({
      ...brand,
      has_shopify: data.has_shopify,
    });
    goToNextStep();
  };

  const onSubmit = (data: OnboardingStepShopifyForm) => {
    onUpdate({ has_shopify: true, shopify_url: data.shopifyUrl });
  };

  const handleHasShopify = () => {
    setSubStep(1);
  };

  const handleCancel = () => {
    onUpdate({ has_shopify: false });
  };

  return (
    <section className="w-full">
      {subStep === 0 ? (
        <div className="mt-[3.625rem] sm:mt-[6.625rem]">
          <Illustration illustration="peaceSparkles" className="mb-4" />
          <h2 className="text-2xl font-bold sm:text-3.5xl">
            {t('onboarding.shopify.sub_steps.1.title')}
          </h2>
          <h3 className="mb-8 mt-6 text-sm font-normal text-gray-700 sm:mb-10 sm:text-base">
            {' '}
            {t('onboarding.shopify.sub_steps.1.subtitle_1')}
            <img
              src={shopify}
              className="mx-2.5 inline-block h-7.5 align-bottom"
            />{' '}
            {t('onboarding.shopify.sub_steps.1.subtitle_2')}
          </h3>
          <div className="flex space-x-4">
            <Button
              type="button"
              size="large"
              onClick={handleCancel}
              className="capitalize"
            >
              {t('no')}
            </Button>
            <Button
              type="button"
              size="large"
              onClick={handleHasShopify}
              className="capitalize"
            >
              {t('yes')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="mb-6 mt-8 text-2xl font-bold sm:mb-10 sm:mt-20 sm:text-3.5xl">
            {t('onboarding.shopify.sub_steps.2.title')}
          </h2>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="shopifyUrl"
                rules={{
                  required: true,
                  pattern: urlPattern,
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('onboarding.shopify.shopify_url.label')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="url"
                        placeholder={t(
                          'onboarding.shopify.shopify_url.placeholder'
                        )}
                        inputMode="url"
                        autoFocus
                        {...field}
                      />
                    </FormControl>
                    <FormMessage match="required">
                      {t(
                        'onboarding.shopify.shopify_url.validation_errors.required'
                      )}
                    </FormMessage>
                    <FormMessage match="pattern">
                      {t(
                        'onboarding.shopify.shopify_url.validation_errors.pattern'
                      )}
                    </FormMessage>
                  </FormItem>
                )}
              />

              <div className="mt-8 flex sm:mt-10">
                <Button type="submit" size="large">
                  {t('continue')}
                </Button>
              </div>
            </form>
          </Form>
          <button
            type="button"
            className="mt-4 text-sm font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
            onClick={handleCancel}
          >
            {t('onboarding.shopify.sub_steps.2.no_shopify')}
          </button>
        </div>
      )}
    </section>
  );
};
