import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';

import { Calendar } from '../../components/calendar/calendar';
import {
  OrderFiltering,
  useFilters,
} from '../../components/order-table/use-filters';
import { useResetList } from '../../components/order-table/use-reset-list';
import { useSorting } from '../../components/order-table/use-sorting';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import {
  OrderTagClaim,
  OrderTagFulfillment,
  OrderTagRefund,
  OrderTagReship,
  OrderTagReturn,
  OrderTagShipment,
  OrderTagShopify,
} from '../../types/generated-new';
import {
  OrderTagClaimReason,
  useOrdersCountersQuery,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { enUS, formatDateFilter, fr } from '../../utils/date';
import { OrderCheckbox } from './order-checkbox';
import { usePagination } from './use-pagination';
import { useSearch } from './use-search';
import { TabEntry, useTabs } from './use-tabs';

export const OrderFiltersModal: React.FC<{
  setOpen: (open: boolean) => void;
}> = ({ setOpen }) => {
  const { filters, setFilters, trackFilters } = useFilters();
  const { resetPagination } = usePagination();
  const { resetList } = useResetList();
  const { resetSearch } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { resetSorting } = useSorting();
  const { setSelectedTab } = useTabs();
  const { i18n, t } = useTranslation();

  const [selectedFilters, setSelectedFilters] =
    useState<OrderFiltering>(filters);

  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const { data: ordersCountersData } = useOrdersCountersQuery(
    {
      sellerId: brand?.id ?? '',
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!brand?.id && !!activeSale?.id,
      select: (data) => data.ordersCounters,
    }
  );

  const FULFILLMENT_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.fulfillment.unfulfilled === 0,
      label: t('order.status.fulfillment.unfulfilled'),
      value: OrderTagFulfillment.Unfulfilled,
    },
    {
      disabled: ordersCountersData?.tagging.fulfillment.fulfilled === 0,
      label: t('order.status.fulfillment.fulfilled'),
      value: OrderTagFulfillment.Fulfilled,
    },
  ];

  const CLAIM_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.claim.opened === 0,
      label: t('order.status.claim.open'),
      value: OrderTagClaim.Opened,
    },
    {
      disabled: ordersCountersData?.tagging.claim.closed === 0,
      label: t('order.status.claim.closed'),
      value: OrderTagClaim.Closed,
    },
  ];

  const CLAIM_REASON_FILTERS = [
    {
      disabled:
        ordersCountersData?.tagging.claimReason
          .notDeliveredAndTrackingDelivered === 0,
      label: t(
        'order.status.claim_reason.not_delivered_and_tracking_delivered'
      ),
      value: OrderTagClaimReason.NotDeliveredAndTrackingDelivered,
    },
    {
      disabled:
        ordersCountersData?.tagging.claimReason.deliveredButDamaged === 0,
      label: t('order.status.claim_reason.delivered_but_damaged'),
      value: OrderTagClaimReason.DeliveredButDamaged,
    },
    {
      disabled:
        ordersCountersData?.tagging.claimReason.deliveredButIncomplete === 0,
      label: t('order.status.claim_reason.delivered_but_incomplete'),
      value: OrderTagClaimReason.DeliveredButIncomplete,
    },
    {
      disabled: ordersCountersData?.tagging.claimReason.deliveredButWrong === 0,
      label: t('order.status.claim_reason.delivered_but_wrong'),
      value: OrderTagClaimReason.DeliveredButWrong,
    },
    {
      disabled:
        ordersCountersData?.tagging.claimReason
          .notDeliveredAndReturnedToSender === 0,
      label: t(
        'order.status.claim_reason.not_delivered_and_returned_to_sender'
      ),
      value: OrderTagClaimReason.NotDeliveredAndReturnedToSender,
    },
    {
      disabled:
        ordersCountersData?.tagging.claimReason
          .notDeliveredAndTrackingBlocked === 0,
      label: t('order.status.claim_reason.not_delivered_and_tracking_blocked'),
      value: OrderTagClaimReason.NotDeliveredAndTrackingBlocked,
    },
    {
      disabled: ordersCountersData?.tagging.claimReason.voucherNotWorking === 0,
      label: t('order.status.claim_reason.voucher_not_working'),
      value: OrderTagClaimReason.VoucherNotWorking,
    },
    {
      disabled: ordersCountersData?.tagging.claimReason.other === 0,
      label: t('order.status.claim_reason.other'),
      value: OrderTagClaimReason.Other,
    },
  ];

  const REFUND_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.refund.partiallyRefunded === 0,
      label: t('order.status.refund.partial'),
      value: OrderTagRefund.PartiallyRefunded,
    },
    {
      disabled: ordersCountersData?.tagging.refund.totallyRefunded === 0,
      label: t('order.status.refund.total'),
      value: OrderTagRefund.TotallyRefunded,
    },
  ];

  const RESHIP_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.reship.reshipped === 0,
      label: t('order.status.reship.reship'),
      value: OrderTagReship.Reshipped,
    },
  ];

  const RETURN_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.return.inProgress === 0,
      label: t('order.status.return.in_progress'),
      value: OrderTagReturn.InProgress,
    },
    {
      disabled: ordersCountersData?.tagging.return.delivered === 0,
      label: t('order.status.return.delivered'),
      value: OrderTagReturn.Delivered,
    },
    {
      disabled: ordersCountersData?.tagging.return.validated === 0,
      label: t('order.status.return.accepted'),
      value: OrderTagReturn.Validated,
    },
  ];

  const SHIPMENT_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.shipment.pending === 0,
      label: t('order.status.shipment.pending'),
      value: OrderTagShipment.Pending,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.infoReceived === 0,
      label: t('order.status.shipment.info_received'),
      value: OrderTagShipment.InfoReceived,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.inTransit === 0,
      label: t('order.status.shipment.in_transit'),
      value: OrderTagShipment.InTransit,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.outForDelivery === 0,
      label: t('order.status.shipment.out_for_delivery'),
      value: OrderTagShipment.OutForDelivery,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.availableForPickup === 0,
      label: t('order.status.shipment.available_for_pickup'),
      value: OrderTagShipment.AvailableForPickup,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.delivered === 0,
      label: t('order.status.shipment.delivered'),
      value: OrderTagShipment.Delivered,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.attemptFail === 0,
      label: t('order.status.shipment.attempt_fail'),
      value: OrderTagShipment.AttemptFail,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.exception === 0,
      label: t('order.status.shipment.exception'),
      value: OrderTagShipment.Exception,
    },
    {
      disabled: ordersCountersData?.tagging.shipment.expired === 0,
      label: t('order.status.shipment.expired'),
      value: OrderTagShipment.Expired,
    },
  ];

  const SHOPIFY_FILTERS = [
    {
      disabled: ordersCountersData?.tagging.shopify.unsynchronized === 0,
      label: t('order.status.shopify.unsynchronized'),
      value: OrderTagShopify.Unsynchronized,
    },
  ];

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex-auto overflow-y-auto p-6 md:p-10">
        <div className="space-y-14">
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('orders.modals.filters.filter_by_date')}
            </h3>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.order_date')}
              </h4>
              <div className="flex items-end gap-6">
                <div className="flex w-full flex-col space-y-2">
                  <label
                    htmlFor="start-date-picker"
                    className="text-xs font-semibold uppercase tracking-wider text-gray-700"
                  >
                    {t('orders.modals.filters.start_date')}
                  </label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button
                        id="start-date-picker"
                        type="button"
                        className={cn(
                          'flex w-full items-center rounded border border-gray-300 p-2 text-left text-sm text-gray-900 focus-visible:ring-2 focus-visible:ring-[#ECECEC]'
                        )}
                        autoFocus
                      >
                        {selectedFilters.creationDate?.from ? (
                          formatDateFilter(
                            new Date(selectedFilters.creationDate.from),
                            i18n.language
                          )
                        ) : (
                          <span className="text-[#9DA0A1]">
                            {t('orders.modals.filters.datepicker.pick_a_date')}
                          </span>
                        )}
                        <Icon
                          icon="calendar"
                          className="ml-auto text-gray-700"
                        />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        lang={i18n.language === 'fr' ? 'fr' : 'en'}
                        locale={i18n.language === 'fr' ? fr : enUS}
                        selected={
                          selectedFilters.creationDate?.from
                            ? new Date(selectedFilters.creationDate.from)
                            : undefined
                        }
                        fromYear={new Date().getFullYear() - 2}
                        toYear={new Date().getFullYear() + 2}
                        defaultMonth={
                          selectedFilters.creationDate?.from
                            ? new Date(selectedFilters.creationDate.from)
                            : new Date()
                        }
                        onSelect={(value) => {
                          if (!value) return;
                          setSelectedFilters((prev) => ({
                            ...prev,
                            creationDate: {
                              from: value.toISOString(),
                              to: prev.creationDate?.to,
                            },
                          }));
                        }}
                        disabled={(date) => {
                          if (!selectedFilters.creationDate?.to) return false;

                          return (
                            date > new Date(selectedFilters.creationDate.to)
                          );
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <Icon
                  icon="back"
                  size="large"
                  className="mb-2 shrink-0 rotate-180 text-gray-900"
                />
                <div className="flex w-full flex-col space-y-2">
                  <label
                    htmlFor="end-date-picker"
                    className="text-xs font-semibold uppercase tracking-wider text-gray-700"
                  >
                    {t('orders.modals.filters.end_date')}
                  </label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button
                        id="end-date-picker"
                        type="button"
                        className={cn(
                          'flex w-full items-center rounded border border-gray-300 p-2 text-left text-sm text-gray-900 focus-visible:ring-2 focus-visible:ring-[#ECECEC]'
                        )}
                        autoFocus
                      >
                        {selectedFilters.creationDate?.to ? (
                          formatDateFilter(
                            new Date(selectedFilters.creationDate.to),
                            i18n.language
                          )
                        ) : (
                          <span className="text-[#9DA0A1]">
                            {t('orders.modals.filters.datepicker.pick_a_date')}
                          </span>
                        )}
                        <Icon
                          icon="calendar"
                          className="ml-auto text-gray-700"
                        />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        lang={i18n.language === 'fr' ? 'fr' : 'en'}
                        locale={i18n.language === 'fr' ? fr : enUS}
                        selected={
                          selectedFilters.creationDate?.to
                            ? new Date(selectedFilters.creationDate.to)
                            : undefined
                        }
                        fromYear={new Date().getFullYear() - 2}
                        toYear={new Date().getFullYear() + 2}
                        defaultMonth={
                          selectedFilters.creationDate?.to
                            ? new Date(selectedFilters.creationDate.to)
                            : new Date()
                        }
                        onSelect={(value) => {
                          if (!value) return;
                          setSelectedFilters((prev) => ({
                            ...prev,
                            creationDate: {
                              from: prev.creationDate?.from,
                              to: value.toISOString(),
                            },
                          }));
                        }}
                        disabled={(date) => {
                          if (!selectedFilters.creationDate?.from) return false;

                          return (
                            date < new Date(selectedFilters.creationDate.from)
                          );
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </section>
          </div>
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('orders.modals.filters.filter_by_status')}
            </h3>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.fulfillment')}
              </h4>
              <div className="flex gap-4">
                {FULFILLMENT_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.fulfillment?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          fulfillment: [
                            ...(prev.fulfillment || []),
                            filter.value,
                          ],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          fulfillment: (prev.fulfillment || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.shipment')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {SHIPMENT_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.shipment?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          shipment: [...(prev.shipment || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          shipment: (prev.shipment || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.claim')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {CLAIM_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.claim?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          claim: [...(prev.claim || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          claim: (prev.claim || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.claim_reason')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {CLAIM_REASON_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.claimReason?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          claimReason: [
                            ...(prev.claimReason || []),
                            filter.value,
                          ],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          claimReason: (prev.claimReason || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.refund')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {REFUND_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.refund?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          refund: [...(prev.refund || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          refund: (prev.refund || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.reship')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {RESHIP_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.reship?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          reship: [...(prev.reship || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          reship: (prev.reship || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.return')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {RETURN_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.return?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          return: [...(prev.return || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          return: (prev.return || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
            <section className="space-y-3">
              <h4 className="border-b border-gray-100 pb-2 font-bold">
                {t('orders.modals.filters.shopify')}
              </h4>
              <div className="flex flex-wrap gap-4">
                {SHOPIFY_FILTERS.map((filter) => (
                  <OrderCheckbox
                    key={filter.value}
                    disabled={filter.disabled}
                    defaultChecked={selectedFilters.shopify?.includes(
                      filter.value
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          shopify: [...(prev.shopify || []), filter.value],
                        }));
                      } else {
                        setSelectedFilters((prev) => ({
                          ...prev,
                          shopify: (prev.shopify || []).filter(
                            (item) => item !== filter.value
                          ),
                        }));
                      }
                    }}
                  >
                    {filter.label}
                  </OrderCheckbox>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-center justify-between border-t border-gray-100 p-6 md:p-10 md:pt-6">
        <a
          href={
            i18n.language === 'fr'
              ? 'https://www.notion.so/choose/Glossaire-des-statuts-Choose-709037616d224046ac7435f8b75ac126?pvs=4'
              : 'https://www.notion.so/choose/Glossary-of-Choose-statuses-2f22a3eb98a44a2b96208ae6d61b5cfd?pvs=4'
          }
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-sm font-semibold text-green-900"
          onClick={() => track('GoToGlossaryStatuses', {})}
        >
          <Icon icon="externalLinkDefault" className="shrink-0" />
          <span>{t('orders.modals.filters.glossary_of_statuses')}</span>
        </a>
        <div className="flex space-x-6">
          <Button
            type="button"
            appearance="outline"
            onClick={() => {
              resetList();
              setOpen(false);
            }}
          >
            {t('orders.modals.filters.actions.clear')}
          </Button>

          <Button
            type="button"
            onClick={() => {
              resetPagination();
              resetSearch();
              resetSorting();
              setFilters(selectedFilters);
              setSelectedTab(TabEntry.Filters);
              setSearchParams((prev) => {
                prev.delete('after');
                prev.delete('before');
                prev.delete('q');
                prev.delete('sort');
                prev.set('view', TabEntry.Filters.toString());
                prev.set('filters', JSON.stringify(selectedFilters));
                return prev;
              });
              setOpen(false);
              trackFilters(selectedFilters);
            }}
          >
            {t('orders.modals.filters.actions.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};
