import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';

import { MiddleTruncate } from '../../components/middle-truncate';
import { InvoiceType, InvoicesQuery } from '../../types/generated-new';
import { formatDate } from '../../utils/date';
import { InvoiceBadge } from './invoice-badge';

type InvoiceListProps = {
  invoices?: InvoicesQuery['invoices'];
};

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const { i18n, t } = useTranslation();

  return (
    <div>
      <ul className="space-y-4">
        {invoices?.map((invoice) => (
          <li
            key={invoice.id}
            className="flex items-center justify-between space-x-2 rounded-lg border border-gray-100 p-4 text-sm"
          >
            <div className="flex items-center space-x-4 truncate">
              <div className="flex items-center space-x-2 truncate">
                <div className="flex items-center space-x-1 truncate">
                  <Icon icon="document" className="shrink-0" />
                  <MiddleTruncate
                    className="max-w-[335px] text-gray-900"
                    text={invoice.fileName}
                  />
                </div>
                <span className="text-[#9DA0A1]">
                  {formatDate(new Date(invoice.createdAt), 'P', i18n.language)}
                </span>
              </div>
              {invoice.type === InvoiceType.DownPayment ? (
                <div className="flex shrink-0 cursor-default items-center space-x-1 rounded-sm bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-700">
                  <span>
                    {t('invoice.invoice_information.type.down_payment')}
                  </span>
                </div>
              ) : null}
              <InvoiceBadge invoiceStatus={invoice.status} />
            </div>
            {invoice.fileUrl ? (
              <a
                href={invoice.fileUrl}
                target="_blank"
                rel="noreferrer"
                className="px-1 py-0.5 text-xs font-semibold text-green-900"
              >
                {t('invoice.invoice_information.see')}
              </a>
            ) : (
              <div className="flex items-center space-x-2">
                <Loader className="size-4" />
                <span className="text-xs text-green-900">
                  {t('invoice.invoice_information.analyzing')}
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
