import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Icon from '@appchoose/icon';

type BrandViewProps = {
  name: string;
  id: string;
};

export const BrandView = ({ name, id }: BrandViewProps) => {
  const { t } = useTranslation();

  return (
    <NavLink
      className="flex items-center justify-between space-x-6 px-4 py-6"
      to={`/${id}/settings/account`}
      target="_blank"
    >
      <div className="truncate text-base font-semibold text-gray-700">
        {name}
      </div>
      <div>
        <div className="flex cursor-pointer items-center space-x-2">
          <div className="text-sm font-semibold text-green-900">
            {t('settings.account_tabs.change_dashboard')}
          </div>
          <Icon
            className="shrink-0 text-green-900"
            icon="externalLinkSimple"
            size="large"
          />
        </div>
      </div>
    </NavLink>
  );
};
