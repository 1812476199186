import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';

import { OrderTagRefund } from '../../types/generated-new';
import { Refund } from '../icons/refund';
import { OrderBadge } from './order-badge';
import { getOrderRefundLabelFromTag } from './order-refund-badge';

export const ModalOrderRefundBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagRefund;
}> = ({ isCancelled, tag }) => {
  const { t } = useTranslation();

  if (tag === OrderTagRefund.None) return null;

  return (
    <OrderBadge style="filled" intent="bold">
      <div className="flex items-center gap-x-1">
        <Refund className="!size-3.5" />
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderRefundLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
