import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Maybe } from 'graphql/jsutils/Maybe';

import { TopProductInfo } from '../../types/generated';
import { transformPrice } from '../../utils/currency';

type TopProductsRowProps = {
  hasSize?: boolean;
  product: Maybe<TopProductInfo>;
  rank: number;
};

export const TopProductsRow: React.FC<TopProductsRowProps> = ({
  hasSize = true,
  product,
  rank,
}: TopProductsRowProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const { i18n, t } = useTranslation();

  const onToggleDetails = () => {
    if ((product?.variants?.length ?? 0) < 2) {
      return;
    }
    setShowDetails(!showDetails);
  };

  return (
    <tbody
      onClick={onToggleDetails}
      className={cn('border-b-0.5', {
        details: (product?.variants?.length ?? 0) > 1,
      })}
    >
      <tr
        className={cn({
          nosize: !hasSize,
          'y-6 grid h-auto py-6': rank <= 2,
          'y-6 grid h-auto py-4': rank >= 3,
        })}
      >
        <td data-column="rank" className="flex items-center">
          <p
            className={cn(
              'm-0 h-6 w-6 rounded-full text-center text-sm font-bold leading-6 text-gray-700',
              {
                'bg-beige-400 text-gray-900': rank === 0,
                'bg-gray-300 text-gray-900': rank === 1,
                'bg-beige-600 text-gray-900': rank === 2,
              }
            )}
          >
            {rank + 1}
          </p>
        </td>
        <td data-column="image" className="flex items-center">
          <img
            width={48}
            height={48}
            src={product?.image_url}
            className="m-0 size-12 rounded border border-gray-300 text-sm font-semibold text-gray-700"
          />
        </td>
        <td data-column="name" className="m-0 flex grow items-center">
          <div className="hidden pr-4 sm:flex sm:flex-col">
            <p className="m-0 line-clamp-2 text-sm font-semibold text-gray-700">
              {product?.product_name}
            </p>
            <p className="m-0 line-clamp-2 text-sm text-gray-700">
              {product?.options.join(' - ')}
            </p>
          </div>
          <div className="flex flex-col pr-4 sm:hidden">
            <p className="m-0 line-clamp-2 text-sm font-semibold text-gray-700">
              <span>{product?.product_name}</span>
              {(product?.variants.length ?? 0) >= 1 &&
              product?.options.length ? (
                <span> - {product?.options.join(' - ')}</span>
              ) : null}
            </p>
            <p className="m-0 line-clamp-2 text-sm text-gray-700">
              {product?.purchased_count} {t('analytics.top_products.sales')} -{' '}
              {transformPrice(
                product?.total_amount,
                i18n.language === 'fr' ? 'fr' : 'en',
                'EUR'
              )}{' '}
              {t('analytics.top_products.revenue')}
            </p>
          </div>
        </td>
        <td
          data-column="sales"
          className="m-0 hidden items-center text-sm font-semibold text-gray-700 sm:flex"
        >
          {product?.purchased_count}
        </td>
        <td
          data-column="revenue"
          className="m-0 hidden items-center text-sm text-gray-700 sm:flex"
        >
          {transformPrice(
            product?.total_amount,
            i18n.language === 'fr' ? 'fr' : 'en',
            'EUR'
          )}
        </td>
        {hasSize && (product?.variants?.length ?? 0) > 1 ? (
          <td
            data-column="size"
            className="m-0 flex items-center text-sm font-semibold text-green-900"
          >
            {showDetails ? (
              <p className="hidden sm:inline-block">
                {t('analytics.top_products.hide_detail')}
              </p>
            ) : (
              <p className="hidden sm:inline-block">
                {t('analytics.top_products.show_detail')}
              </p>
            )}
            <Icon
              icon={showDetails ? 'arrowUp' : 'arrowDown'}
              size="large"
              className="ml-1 inline-block sm:hidden"
            />
            <Icon
              icon={showDetails ? 'arrowUp' : 'arrowDown'}
              size="medium"
              className="ml-1 hidden sm:inline-block"
            />
          </td>
        ) : null}
        {hasSize && (product?.variants?.length ?? 0) === 1 ? (
          <td
            data-column="size"
            className="m-0 flex items-center text-sm text-gray-700"
          >
            <p className="hidden sm:block">
              {t('analytics.top_products.one_size')}
            </p>
          </td>
        ) : null}
      </tr>

      <tr
        className={cn('y-4 sm:y-6 grid h-auto p-0', {
          nosize: !hasSize,
          hidden: !showDetails,
        })}
      >
        <td data-column="rank" className="flex items-center"></td>
        <td data-column="image" className="flex items-center"></td>
        <td data-column="name" className="m-0 flex grow items-center">
          <div className="flex flex-col pr-4">
            <p className="m-0 mb-2 text-xs font-semibold uppercase text-gray-500 sm:hidden">
              {t('analytics.top_products.sizes')}
            </p>
            {product?.variants.map((variant) => (
              <p
                key={variant.variant_id}
                className="m-0 pb-2 text-sm text-gray-700 sm:pb-4"
              >
                <span>{variant.option_value}</span>
                <span className="inline sm:hidden">
                  {' '}
                  - {variant.purchased_count}{' '}
                  {t('analytics.top_products.sales')}
                </span>
                <span className="inline sm:hidden">
                  {' '}
                  -{' '}
                  {transformPrice(
                    variant.total_amount,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    'EUR'
                  )}{' '}
                  {t('analytics.top_products.revenue')}
                </span>
              </p>
            ))}
          </div>
        </td>
        <td
          data-column="sales"
          className="m-0 hidden items-center text-sm font-semibold text-gray-700 sm:flex"
        >
          <div className="flex flex-col">
            {product?.variants.map((variant) => (
              <p
                key={variant.variant_id}
                className="m-0 pb-4 text-sm text-gray-700"
              >
                {variant.purchased_count}
              </p>
            ))}
          </div>
        </td>
        <td
          data-column="revenue"
          className="m-0 hidden items-center text-sm text-gray-700 sm:flex"
        >
          <div className="flex flex-col">
            {product?.variants.map((variant) => (
              <p
                key={variant.variant_id}
                className="m-0 pb-4 text-sm text-gray-700"
              >
                {transformPrice(
                  variant.total_amount,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  'EUR'
                )}
              </p>
            ))}
          </div>
        </td>
        {hasSize ? (
          <td
            data-column="size"
            className="m-0 flex items-center text-sm text-gray-700"
          ></td>
        ) : null}
      </tr>
    </tbody>
  );
};

TopProductsRow.displayName = 'TopProductsRow';
