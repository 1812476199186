export const OrderCardSkeleton: React.FC = () => {
  return (
    <li className="animate-pulse space-y-3 rounded border border-gray-100 bg-white p-4">
      <div className="flex items-center gap-x-2">
        <div className="h-4 w-[5.625rem] rounded-sm bg-gray-300"></div>
        <span className="text-xs text-gray-700">•</span>
        <div className="h-4 w-[5.625rem] rounded-sm bg-gray-300"></div>
      </div>
      <div className="h-5.5 w-[5.625rem] rounded-sm bg-gray-300"></div>
      <div className="h-7 w-[5.625rem] rounded-sm bg-gray-300"></div>
      <div className="flex items-center justify-between">
        <div className="flex flex-nowrap gap-x-1">
          <div className="size-8 rounded-[0.1875rem] bg-gray-300"></div>
          <div className="size-8 rounded-[0.1875rem] bg-gray-300"></div>
          <div className="size-8 rounded-[0.1875rem] bg-gray-300"></div>
        </div>
        <div className="h-5.5 w-[5.625rem] rounded-[0.1875rem] bg-gray-300"></div>
      </div>
    </li>
  );
};
