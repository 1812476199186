import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalTitle,
  ModalTrigger,
} from '@appchoose/modal';

import { activeSaleState } from '../../stores/sales';
import { amDefaultContact, findContact } from '../../types/choose-contact';
import { track } from '../../utils/analytics';
import { AmContact } from '../billing-screen/am-contact';

export const Commitments: React.FC = () => {
  const { t } = useTranslation();

  const activeSale = useRecoilValue(activeSaleState);

  const amContact = findContact(
    activeSale?.logistics_manager ?? undefined,
    amDefaultContact
  );

  const commitments = [
    {
      key: '1',
      icon: 'clock' as const,
      title: t('home.commitments.commitment_1'),
      detail: t('home.commitments.commitment_1_detail'),
    },
    {
      key: '2',
      icon: 'package' as const,
      title: t('home.commitments.commitment_2'),
      detail: t('home.commitments.commitment_2_detail'),
    },
    {
      key: '3',
      icon: 'complains' as const,
      title: t('home.commitments.commitment_3'),
      detail: t('home.commitments.commitment_3_detail'),
    },
    {
      key: '4',
      icon: 'closeCircle' as const,
      title: t('home.commitments.commitment_4'),
      detail: t('home.commitments.commitment_4_detail'),
    },
  ];

  return (
    <section className="rounded-lg border border-gray-100 p-6">
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="rounded border border-gray-100 bg-[#FBFBFB] p-1 text-gray-900">
              <Icon icon="agreement" className="size-6" />
            </div>
            <span className="text-sm font-semibold text-gray-900">
              {t('home.commitments.commitments')}
            </span>
          </div>
          <Modal>
            <ModalTrigger asChild>
              <button
                type="button"
                onClick={() => track('OpenModalCommitments', {})}
                className="flex items-center rounded border border-gray-100 bg-[#FBFBFB] px-3 py-2 text-xs font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]"
              >
                {t('home.commitments.show_details')}
              </button>
            </ModalTrigger>
            <ModalContent>
              <div className="flex flex-auto flex-col gap-10 overflow-y-auto p-6 md:p-10">
                <ModalTitle>
                  {t('home.commitments.operational_commitments')}
                </ModalTitle>

                <div className="grid grid-cols-2 gap-10">
                  {commitments.map((commitment) => (
                    <div
                      key={commitment.key}
                      className="flex flex-col items-start gap-3"
                    >
                      <div className="rounded border border-gray-300 bg-[#FBFBFB] p-2">
                        <Icon
                          icon={commitment.icon}
                          className="size-6 text-gray-600"
                        />
                      </div>
                      <div className="flex flex-col gap-0.5">
                        <p className="font-semibold text-gray-900">
                          {commitment.title}
                        </p>
                        <p className="text-sm leading-5.5 text-gray-700">
                          {commitment.detail}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <ModalFooter>
                <ModalClose asChild>
                  <Button type="button">{t('close')}</Button>
                </ModalClose>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {commitments.map((commitment) => (
            <div
              key={commitment.key}
              className="flex flex-col gap-3 rounded-lg border border-gray-100 p-4"
            >
              <Icon icon={commitment.icon} className="size-6 text-gray-600" />
              <p className="text-sm font-semibold text-gray-900">
                {commitment.title}
              </p>
            </div>
          ))}
        </div>
        <div className="rounded-lg border border-gray-100 p-6">
          <AmContact contact={amContact} />
        </div>
      </div>
    </section>
  );
};
