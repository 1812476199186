import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import Rating from '@appchoose/rating';
import Tooltip from '@appchoose/tooltip';
import * as Accordion from '@radix-ui/react-accordion';

import { orderDetailUpdatedIdState } from '../../hooks/use-order-updated-subscription';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import { useOrderQuery } from '../../types/generated-new';
import { transformPrice } from '../../utils/currency';
import { transformFullDate } from '../../utils/date';
import { getReturnReasonLabel } from '../../utils/utils';
import { Copy } from '../copy/copy';
import { RefundChoose } from '../icons/refund-choose';
import { ImageWithQuantity } from '../image-with-quantity/image-with-quantity';
import { ModalOrderClaimBadge } from '../order-status-badge/modal-order-claim-badge';
import { ModalOrderFulfillmentBadge } from '../order-status-badge/modal-order-fulfillment-badge';
import { ModalOrderRefundBadge } from '../order-status-badge/modal-order-refund-badge';
import { ModalOrderReshipBadge } from '../order-status-badge/modal-order-reship-badge';
import { ModalOrderReturnBadge } from '../order-status-badge/modal-order-return-badge';
import { ModalOrderShipmentBadge } from '../order-status-badge/modal-order-shipment-badge';
import { ModalOrderClaimDetails } from './modal-order-claim-details';
import { ModalOrderDelayAlert } from './modal-order-delay-alert';
import { ModalOrderInvoice } from './modal-order-invoice';
import { ModalOrderMobileHeader } from './modal-order-mobile-header';
import {
  ModalOrderOrderNavigation,
  OrderNavigation,
} from './modal-order-order-navigation';
import { ModalOrderParcel } from './modal-order-parcel';
import { ModalOrderPickupPoint } from './modal-order-pickup-point';
import { ModalOrderRecipientAddress } from './modal-order-recipient-address';
import { ModalOrderRecipientDetails } from './modal-order-recipient-details';
import { ModalOrderRecipientName } from './modal-order-recipient-name';
import { ModalOrderRefundDetails } from './modal-order-refund-details';
import { ModalOrderReshipDetails } from './modal-order-reship-details';
import { ModalOrderReturnInitial } from './modal-order-return-initial';
import { ModalOrderReturnParcel } from './modal-order-return-parcel';
import { ModalOrderShopify } from './modal-order-shopify';
import { ModalOrderUpdatedBanner } from './modal-order-updated-banner';
import { ScrollToTop } from './scroll-to-top';

import '@appchoose/tooltip/dist/style.css';

export type ModalOrderProps = {
  id: string;
} & OrderNavigation;

export const ModalOrder: React.FC<ModalOrderProps> = ({
  goToNextPageAndOrder,
  goToPreviousPageAndOrder,
  hasNextPage,
  hasPreviousPage,
  id,
  nextOrderId,
  previousOrderId,
}: ModalOrderProps) => {
  const { i18n, t } = useTranslation();

  const [orderDetailUpdatedId, setOrderDetailUpdatedId] = useRecoilState(
    orderDetailUpdatedIdState
  );
  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const {
    data: order,
    isLoading: orderLoading,
    error: orderError,
  } = useOrderQuery(
    {
      orderId: id,
    },
    {
      enabled: !!id,
      select: (result) => result.order,
    }
  );

  useEffect(() => {
    if (orderDetailUpdatedId) {
      setOrderDetailUpdatedId(null);
    }
  }, [order]);

  if (orderLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader className="size-12" />
      </div>
    );
  }

  if ((orderError as Error)?.message === 'No orders found') {
    return (
      <div className="p-10">
        <Alert
          title={t('order.errors.not_found')}
          appearance="error"
          icon="alertCircle"
          size="small"
        >
          <p className="text-sm text-gray-700">
            {t('order.errors.not_found_description')}
          </p>
        </Alert>
      </div>
    );
  }

  if (!order) return null;

  const hasCancelledItems = order.items?.some((item) => item.isCancelled);

  return (
    <div className="relative h-full overflow-auto pt-18 lg:pt-0">
      <ScrollToTop />
      <ModalOrderMobileHeader
        brandId={brand?.id ?? ''}
        goToNextPageAndOrder={goToNextPageAndOrder}
        goToPreviousPageAndOrder={goToPreviousPageAndOrder}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        nextOrderId={nextOrderId}
        previousOrderId={previousOrderId}
      />
      <ModalOrderUpdatedBanner />
      <div className="space-y-10 px-4 pb-12 lg:p-10">
        <div className="pt-6 lg:pt-0">
          <ModalOrderReturnInitial initialOrderId={order.initialOrderId} />
          <div className="mb-4 flex flex-wrap items-center gap-3">
            <ModalOrderFulfillmentBadge
              tag={order.tags.fulfillment}
              isCancelled={order.isCancelled}
            />
            <ModalOrderShipmentBadge
              hasTrackingStucked={order.parcels?.[0]?.hasTrackingStucked}
              isCancelled={order.isCancelled}
              tag={order.tags.shipment[0]}
              trackingSubStatus={order.parcels?.[0]?.trackingSubStatus}
            />
            <ModalOrderClaimBadge
              tag={order.tags.claim[0]}
              isCancelled={order.isCancelled}
            />
            <ModalOrderRefundBadge
              tag={order.tags.refund}
              isCancelled={order.isCancelled}
            />
            <ModalOrderReshipBadge
              tag={order.tags.reship}
              isCancelled={order.isCancelled}
            />
            <ModalOrderReturnBadge
              tag={order.tags.return[0]}
              isCancelled={order.isCancelled}
            />
          </div>
          <div className="mb-4 flex justify-between">
            <div className="space-y-1">
              <div className="flex space-x-1">
                <h4
                  id="modal-order-title"
                  className="text-xl font-bold text-gray-900"
                >
                  {order.id}
                </h4>
                <Copy
                  className="text-gray-500"
                  tabIndex={-1}
                  value={order.id}
                  successMessage={t('clipboard.copied')}
                  trackLabel="order_id"
                >
                  <Icon icon="copy" />
                </Copy>
              </div>
              {order.createdAt && (
                <p className="text-xs text-[#767A7C]">
                  {transformFullDate(
                    new Date(order.createdAt),
                    i18n.language === 'fr' ? 'fr' : 'en',
                    brand?.timezone ?? undefined
                  )}
                </p>
              )}
              {order.integration?.shopify?.orderUrl ? (
                <a
                  href={order.integration?.shopify?.orderUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex text-xs font-semibold text-green-900"
                >
                  {t('order.show_in_shopify')}
                  <Icon icon="externalLinkSimple" />
                </a>
              ) : null}
            </div>
            <ModalOrderOrderNavigation
              brandId={brand?.id ?? ''}
              className="hidden lg:flex"
              goToNextPageAndOrder={goToNextPageAndOrder}
              goToPreviousPageAndOrder={goToPreviousPageAndOrder}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              nextOrderId={nextOrderId}
              previousOrderId={previousOrderId}
            />
          </div>
          <div className="space-y-4">
            <ModalOrderShopify order={order} />
            <ModalOrderDelayAlert order={order} />
          </div>
        </div>
        <section className="space-y-4">
          <ModalOrderReturnParcel
            returnParcel={order?.returnParcels?.[0]}
            tags={order.tags}
          />

          <ModalOrderReshipDetails
            reshipOrder={order.reshipOrder}
            reshipOrderId={order.reshipOrderId}
            tags={order.tags}
          />

          <ModalOrderRefundDetails
            items={order?.items}
            linesRefunds={order?.linesRefunds}
            tags={order?.tags}
          />

          <ModalOrderClaimDetails
            claim={order.claims?.[0]}
            order={order}
            parcel={order?.parcels?.[0]}
            tags={order.tags}
          />

          <ModalOrderParcel
            isFullDigital={order?.isFullDigital ?? false}
            orderId={order?.id ?? ''}
            parcel={order?.parcels?.[0]}
            shipping={order?.shipping}
            tags={order.tags}
          />
        </section>
        <section>
          <header className="flex items-center justify-between py-4">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('order.items')}
            </h3>
            <p className="text-xs">
              <span
                className={cn({
                  'text-gray-300 line-through': hasCancelledItems,
                })}
              >
                {transformPrice(
                  brand?.store === StoreRegion.Fr
                    ? order.totalPriceSold?.valueWithVat
                    : order.totalPriceSold?.valueWithoutVat || 0,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  order.totalPriceSold?.currency.toString().toUpperCase() ||
                    'EUR'
                )}
              </span>{' '}
              {hasCancelledItems ? (
                <span className="ml-2">
                  {transformPrice(
                    order.items
                      ?.filter((item) => !item.isCancelled)
                      .map((item) =>
                        brand?.store === StoreRegion.Fr
                          ? item.priceSold.valueWithVat
                          : item.priceSold.valueWithoutVat
                      )
                      .reduce((a, b) => a + b, 0),
                    i18n.language === 'fr' ? 'fr' : 'en',
                    order.totalPriceSold?.currency.toString().toUpperCase() ||
                      'EUR'
                  )}
                </span>
              ) : null}
              {order.shipping?.fees ? (
                <span>
                  {' '}
                  ({t('with')}{' '}
                  {transformPrice(
                    order.shipping.fees,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
                  )}{' '}
                  {t('fdp')})
                </span>
              ) : null}
            </p>
          </header>
          <div className="space-y-4">
            {order.items?.map((item) => (
              <div
                key={item.id}
                className="flex flex-row items-start space-x-4"
              >
                <ImageWithQuantity
                  imageUrl={item.imageUrl || ''}
                  quantity={1}
                  className={cn('size-20', {
                    'opacity-25': item.isCancelled,
                  })}
                />
                <div className="flex min-h-20 flex-1 flex-col justify-center space-y-1">
                  <p>
                    <span
                      className={cn('text-sm font-semibold', {
                        'text-gray-900': !item.isCancelled,
                        'text-gray-500': item.isCancelled,
                      })}
                    >
                      {item.name}
                    </span>{' '}
                    {item.isOffered && (
                      <span className="mr-1 text-sm lowercase text-orange-600">
                        ({t('order.is_offered')})
                      </span>
                    )}
                    <span
                      className={`text-sm ${
                        item.isCancelled ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      (
                      {transformPrice(
                        item.priceSold.valueWithVat,
                        i18n.language === 'fr' ? 'fr' : 'en',
                        item.priceSold.currency
                      )}
                      )
                    </span>
                  </p>
                  {item.size ? (
                    <p
                      className={cn(`text-xs`, {
                        'text-gray-700': !item.isCancelled,
                        'text-gray-500': item.isCancelled,
                      })}
                    >
                      {item.size}
                    </p>
                  ) : null}
                  {item.sku ? (
                    <p
                      className={cn(`text-xs`, {
                        'text-gray-700': !item.isCancelled,
                        'text-gray-500': item.isCancelled,
                      })}
                    >
                      <span className="uppercase">{t('order.sku')}</span>{' '}
                      {item.sku}
                    </p>
                  ) : null}
                  {item.newDigitalCouponCode || item.digitalCouponCode ? (
                    <div className="flex w-full items-start justify-between space-x-4">
                      <p className="break-all text-xs text-[#767A7C]">
                        <span className="uppercase">
                          {item.newDigitalCouponCode ?? item.digitalCouponCode}
                        </span>
                      </p>
                    </div>
                  ) : null}
                  {!item.isCancelled &&
                  (item.isReturnGenerated ||
                    item.refundSummary.refundedAmountInPercentage > 0) ? (
                    <Accordion.Root
                      type="single"
                      disabled={
                        !item.refundSummary
                          .refundedAmountInPercentageCoveredByChoose ||
                        !item.refundSummary
                          .refundedAmountInPercentageCoveredBySupplier
                      }
                      collapsible
                      className="space-x-2 border-l border-gray-100 pl-2 text-xs text-gray-700"
                    >
                      <Accordion.Item value="refund-details">
                        <Accordion.Header>
                          <Accordion.Trigger className="group flex items-center space-x-1">
                            {item.isReturnGenerated ? (
                              <Fragment>
                                {item.returnReasonCode ? (
                                  <span className="font-bold">
                                    {t('order.returned_with_reason')}{' '}
                                    {getReturnReasonLabel(
                                      item.returnReasonCode
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-bold">
                                    {t('order.returned')}
                                  </span>
                                )}
                              </Fragment>
                            ) : null}
                            {item.isReturnGenerated &&
                            item.refundSummary.refundedAmountInPercentage >
                              0 ? (
                              <span className="text-gray-300">•</span>
                            ) : null}
                            {item.refundSummary.refundedAmountInPercentage >
                            0 ? (
                              <span className="text-xs font-semibold text-orange-600">
                                {item.refundSummary.isTotallyRefunded
                                  ? t('order.refunded')
                                  : t('order.x_refunded', {
                                      count:
                                        item.refundSummary
                                          .refundedAmountInPercentage,
                                    })}
                              </span>
                            ) : null}
                            {item.refundSummary
                              .refundedAmountInPercentageCoveredByChoose !==
                              0 &&
                            item.refundSummary
                              .refundedAmountInPercentageCoveredBySupplier ===
                              0 ? (
                              <Tooltip
                                offset={[0, 16]}
                                content={
                                  <div className="px-1.5 py-3 text-xs font-semibold">
                                    <p className="text-center">
                                      {t('order.refund.payable_by_choose')}
                                    </p>
                                  </div>
                                }
                              >
                                <div>
                                  <RefundChoose className="text-orange-600" />
                                </div>
                              </Tooltip>
                            ) : null}
                            <Icon
                              icon="arrowDown"
                              className="text-orange-600 transition-transform group-data-[disabled]:hidden group-data-[state=open]:rotate-180"
                            />
                          </Accordion.Trigger>
                        </Accordion.Header>
                        <Accordion.Content>
                          <div className="ml-2 mt-1 space-y-1 text-xs font-normal text-gray-900">
                            {item.refundSummary
                              .refundedAmountInPercentageCoveredByChoose ? (
                              <p>
                                {item.refundSummary.isTotallyRefunded ||
                                !item.refundSummary
                                  .refundedAmountInPercentageCoveredBySupplier
                                  ? t('order.refund.refunded_by_choose')
                                  : t('order.refund.x_refunded_by_choose', {
                                      amount:
                                        item.refundSummary
                                          .refundedAmountInPercentageCoveredByChoose,
                                    })}
                              </p>
                            ) : null}
                            {item.refundSummary
                              .refundedAmountInPercentageCoveredBySupplier ? (
                              <p>
                                {item.refundSummary.isTotallyRefunded ||
                                !item.refundSummary
                                  .refundedAmountInPercentageCoveredByChoose
                                  ? t('order.refund.refunded_by_supplier')
                                  : t('order.refund.x_refunded_by_supplier', {
                                      amount:
                                        item.refundSummary
                                          .refundedAmountInPercentageCoveredBySupplier,
                                    })}
                              </p>
                            ) : null}
                          </div>
                        </Accordion.Content>
                      </Accordion.Item>
                    </Accordion.Root>
                  ) : null}
                  {item.isCancelled ? (
                    <div className="flex items-center space-x-2">
                      <div className="h-4 w-0.5 shrink-0 bg-gray-100"></div>
                      <p className="text-xs font-semibold text-orange-600">
                        {t('order.cancelled')}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section>
          <header className="items-center justify-between py-4 md:flex">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('order.shipping_info')}
            </h3>
            {order.recipient?.previous || order.initialOrderId ? (
              <div className="flex items-center">
                <Icon icon="check" className="mr-1.5 text-green-600" />
                <div className="text-sm leading-5.5 text-green-600">
                  {order.recipient?.previous
                    ? t('order.updated_address')
                    : t('order.new_address')}
                </div>
              </div>
            ) : null}
          </header>
          <div className="space-y-4">
            {order.shipping?.pickupPoint ? (
              <>
                <ModalOrderPickupPoint order={order} />
                <ModalOrderRecipientName order={order} />
              </>
            ) : (
              <ModalOrderRecipientAddress order={order} />
            )}

            <ModalOrderRecipientDetails order={order} />
          </div>
        </section>
        {order.rating?.rate ? (
          <section>
            <header className="items-center">
              <h3 className="mb-4 text-2xl font-bold">{t('order.rate')}</h3>
            </header>
            <div className="space-y-4">
              <Rating value={order.rating?.rate} />
              <p className="text-sm text-gray-700">{order.rating?.message}</p>
            </div>
          </section>
        ) : null}
        {brand?.store === StoreRegion.Us ? (
          <section>
            <header className="items-center">
              <h3 className="mb-4 text-2xl font-bold">
                {t('order.invoice.commercial_invoice')}
              </h3>
            </header>
            <ModalOrderInvoice orderId={order.id} />
          </section>
        ) : null}
      </div>
    </div>
  );
};

ModalOrder.displayName = 'ModalOrder';
