type SendProps = React.ComponentPropsWithoutRef<'svg'>;

export const Send: React.FC<SendProps> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.145 6.25781L5.90723 21.6129L13.7747 24.5429M33.145 6.25781L28.6415 30.4028L21.7033 27.5331M33.145 6.25781L13.7747 24.5429M33.145 6.25781L18.2781 26.1164M18.2781 26.1164L16.9759 33.4413M18.2781 26.1164L21.7033 27.5331M16.9759 33.4413L13.7747 24.5429M16.9759 33.4413L21.7033 27.5331"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
