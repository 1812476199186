import { useTranslation } from 'react-i18next';

import { InvoiceList } from './invoice-list';
import { useInvoices } from './use-invoices';

export const Invoices: React.FC = () => {
  const { t } = useTranslation();
  const { data: invoices } = useInvoices();

  if (!invoices || invoices.length === 0) {
    return null;
  }

  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-2xl font-bold text-gray-900">
        {t('invoice.invoices.my_invoices')}
      </h3>
      <InvoiceList invoices={invoices} />
    </section>
  );
};
