type ChronoProps = React.ComponentPropsWithoutRef<'svg'>;

export const Chrono: React.FC<ChronoProps> = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0001 9.43338C14.7903 9.43338 7.32422 16.8356 7.32422 25.9667C7.32422 35.0978 14.7903 42.5001 24.0001 42.5001C33.2099 42.5001 40.676 35.0978 40.676 25.9667C40.676 21.4115 38.8179 17.2865 35.8122 14.2962M24.0001 9.43338V5.30005M24.0001 9.43338C28.6154 9.43338 32.7928 11.2923 35.8122 14.2962M24.0001 5.30005H19.8311M24.0001 5.30005H28.1691M35.8122 14.2962L38.5915 11.5001M26.077 24.1453C25.5696 23.5704 24.8272 23.2078 24.0001 23.2078C22.4707 23.2078 21.2309 24.4476 21.2309 25.977C21.2309 27.5064 22.4707 28.7462 24.0001 28.7462C25.5295 28.7462 26.7693 27.5064 26.7693 25.977C26.7693 25.2747 26.5079 24.6334 26.077 24.1453ZM26.077 24.1453L29.5385 20.4385"
        stroke="currentColor"
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
