export const getSearchParam = (parameter: string) => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has(parameter)) {
    return searchParams.get(parameter) ?? undefined;
  }
  return undefined;
};

export const getComplexSearchParam = (parameter: string) => {
  const searchParams = getSearchParam(parameter);
  if (searchParams) {
    return parseJSON(searchParams);
  }
  return undefined;
};

export const parseJSON = (json: string): unknown => {
  try {
    return JSON.parse(json);
  } catch {
    return undefined;
  }
};
