import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormField, FormItem, FormLabel } from '@appchoose/form';
import Input from '@appchoose/input';

type ShippingDelayFormFieldsProps = {
  autoFocus?: boolean;
};

export type ShippingDelayFormData = {
  deliveryDelaysMin: number;
  deliveryDelaysMax: number;
};

export const ShippingDelayFormFields: React.FC<
  ShippingDelayFormFieldsProps
> = ({ autoFocus }) => {
  const { t } = useTranslation();

  const form = useFormContext<ShippingDelayFormData>();

  const DeliveryDelaysMinErrorMessage =
    (form.formState.errors.deliveryDelaysMin?.type === 'required' &&
      `${t(
        'onboarding.shipping.delivery_delays_min.validation_errors.required'
      )}`) ||
    (form.formState.errors.deliveryDelaysMin?.type === 'min' &&
      `${t('onboarding.shipping.delivery_delays_min.validation_errors.min', {
        min: '0',
      })}`) ||
    (form.formState.errors.deliveryDelaysMin?.type === 'minLowerThanMax' &&
      `${t(
        'onboarding.shipping.delivery_delays_min.validation_errors.min_lower_than_max'
      )}`);

  const DeliveryDelaysMaxErrorMessage =
    (form.formState.errors.deliveryDelaysMax?.type === 'required' &&
      `${t(
        'onboarding.shipping.delivery_delays_max.validation_errors.required'
      )}`) ||
    (form.formState.errors.deliveryDelaysMax?.type === 'min' &&
      `${t('onboarding.shipping.delivery_delays_max.validation_errors.min', {
        min: '0',
      })}`);

  return (
    <div className="mb-8 sm:mb-10">
      <div className="flex items-center space-x-2 text-gray-900">
        <span>{t('between')}</span>
        <div className="w-14">
          <FormField
            control={form.control}
            name="deliveryDelaysMin"
            rules={{
              required: true,
              min: 0,
              validate: {
                minLowerThanMax: (value) => {
                  // Delay minLowerThanMax validation until deliveryDelaysMin and deliveryDelaysMax are filled
                  if (!value || !form.getValues('deliveryDelaysMax'))
                    return true;
                  return value < form.getValues('deliveryDelaysMax');
                },
              },
              deps: ['deliveryDelaysMax'],
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sr-only">
                  {t('onboarding.shipping.delivery_delays_min.label')}
                </FormLabel>
                <FormControl className="-mt-2">
                  <Input
                    type="number"
                    step={1}
                    min={0}
                    inputMode="numeric"
                    autoFocus={autoFocus}
                    {...field}
                    onChange={(event) => {
                      field.onChange(
                        !isNaN(event.target.valueAsNumber)
                          ? event.target.valueAsNumber
                          : null
                      );
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <span>{t('and')}</span>
        <div className="w-14">
          <FormField
            control={form.control}
            name="deliveryDelaysMax"
            rules={{
              required: true,
              min: 1,
              deps: ['deliveryDelaysMin'],
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sr-only">
                  {t('onboarding.shipping.delivery_delays_max.label')}
                </FormLabel>
                <FormControl className="-mt-2">
                  <Input
                    type="number"
                    step={1}
                    min={0}
                    inputMode="numeric"
                    {...field}
                    onChange={(event) => {
                      field.onChange(
                        !isNaN(event.target.valueAsNumber)
                          ? event.target.valueAsNumber
                          : null
                      );
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <span>{t('non_working_days')}.</span>
      </div>
      <div className="text-xs text-red-600">
        <p>{DeliveryDelaysMinErrorMessage}</p>
        <p>{DeliveryDelaysMaxErrorMessage}</p>
      </div>
    </div>
  );
};
