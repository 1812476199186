import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import Loader from '@appchoose/loader';
import { useAuth0 } from '@auth0/auth0-react';
import MD5 from 'crypto-js/md5';

import { useSupportChat } from '../../hooks/use-support-chat';
import { brandState, serverTimeState } from '../../stores/brand';
import {
  RawSale,
  activeSaleIdState,
  getInitialActiveSaleId,
  salesState,
} from '../../stores/sales';
import { StoreRegion, useBrandQuery } from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { setUserId } from '../../utils/analytics';

type FullBrandInfoProps = {
  children: React.ReactNode;
};

export const FullBrandInfo: React.FC<FullBrandInfoProps> = ({
  children,
}: FullBrandInfoProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();
  const { user } = useAuth0();
  const { setSupportPlatform } = useSupportChat();

  const [isLoading, setIsLoading] = React.useState(true);
  const { data: brandData, error: brandError } = useBrandQuery(undefined, {
    enabled: !!brandId && brandId !== 'undefined',
    refetchOnWindowFocus: false,
  });

  const setServerTime = useSetRecoilState(serverTimeState);
  const setBrand = useSetRecoilState(brandState);
  const setActiveSaleId = useSetRecoilState(activeSaleIdState);
  const setSales = useSetRecoilState(salesState);

  const queryParams = new URLSearchParams(location.search);

  // /<id>/<path>
  const path = location.pathname.split('/')?.[2] || '';

  useEffect(() => {
    if (brandData?.brand) {
      const { brand } = brandData;
      const hashedEmail = MD5(user?.email ?? '').toString();
      setUserId(hashedEmail);

      setBrand({
        ...brand,
        billing_address: brand.address,
        shipping_country:
          brand.shipping_country ??
          (brand.store === StoreRegion.Fr ? 'FR' : 'US'),
      });

      const sales = brand?.sales?.filter((sale): sale is RawSale => !!sale);
      const serverTime = brand?.serverTime
        ? new Date(brand.serverTime)
        : undefined;
      setServerTime(serverTime);
      const activeSaleId = getInitialActiveSaleId(
        queryParams.get('sid') ?? undefined,
        sales,
        serverTime ?? new Date()
      );
      setActiveSaleId(activeSaleId);
      setSales(sales);
      const activeSale = sales?.find((sale) => sale.id === activeSaleId);
      setSupportPlatform(activeSale);

      if (user) {
        if (user['https://appchoose.io/claims/isInternalUser']) {
          if (user['https://appchoose.io/claims/needOnboarding'] === true) {
            navigate(`/${brandId}/onboarding-update-profile`, {
              replace: true,
            });
          }
        } else {
          if (brand.accept_contract) {
            if (user['https://appchoose.io/claims/needOnboarding'] === true) {
              navigate(`/${brandId}/onboarding-update-profile`, {
                replace: true,
              });
            }
          } else {
            if (path !== 'onboarding') {
              if (user['https://appchoose.io/claims/needOnboarding'] === true) {
                navigate(`/${brandId}/onboarding/1/2`, { replace: true });
              } else {
                navigate(`/${brandId}/onboarding/2`, { replace: true });
              }
            }
          }
        }
      }

      setIsLoading(false);
    }
  }, [brandData]);

  useEffect(() => {
    if (brandError) {
      setIsLoading(false);
    }
  }, [brandError]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="size-8" />
      </div>
    );
  }

  return children;
};

FullBrandInfo.displayName = 'BrandInfo';
