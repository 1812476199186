type ClosedProps = React.ComponentPropsWithoutRef<'svg'>;

export const Closed: React.FC<ClosedProps> = ({ ...props }: ClosedProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.54388 22.7135C8.84404 21.3309 6.19507 21.4358 6.19507 23.9798C6.19507 26.5238 8.5162 27.3893 9.54388 25.4485M11.7812 21.8292V26.4844H13.84M21.0523 25.6452C21.7604 27.2604 24.5273 26.4582 23.8192 24.9109C23.3125 23.8037 21.3014 24.1765 21.1965 22.9439C21.0916 21.7112 22.875 21.3441 23.7143 22.5636M28.435 26.4713H26.0615V24.0869M28.3432 21.8384H26.0615V24.0869M26.0615 24.0869H28.0285M20 3C19.4477 3 19 3.44772 19 4C19 4.01917 19.0005 4.03822 19.0016 4.05712M20 3C20.5523 3 21 3.44772 21 4C21 4.01917 20.9995 4.03822 20.9984 4.05712M20 3L19.0016 4.05712M20 3L20.9984 4.05712M11.5 12H5C3.89543 12 3 12.8954 3 14V34C3 35.1046 3.89543 36 5 36H35C36.1046 36 37 35.1046 37 34V14C37 12.8954 36.1046 12 35 12H28.5M11.5 12H28.5M11.5 12L19.0016 4.05712M28.5 12L20.9984 4.05712M19.0016 4.05712C19.0312 4.58283 19.4669 5 20 5C20.5331 5 20.9688 4.58283 20.9984 4.05712M18.9804 24.1765C18.9804 25.2256 18.3772 26.4844 17.2363 26.4844C16.0955 26.4844 15.5447 25.4218 15.5447 24.1765C15.5447 22.9312 16.2791 21.8292 17.2363 21.8292C18.1936 21.8292 18.9804 23.1275 18.9804 24.1765ZM30.7829 26.4713V21.8384C31.8943 21.8384 33.7579 22.0885 33.8041 24.2334C33.8503 26.3783 32.1604 26.4713 30.7829 26.4713Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
