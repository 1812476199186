import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';

import { Checklist } from '../../components/icons/checklist';
import { brandState } from '../../stores/brand';
import { shortActiveSaleIdState } from '../../stores/sales';

export const InvoiceLockedSaleEndedNeedActions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const brand = useRecoilValue(brandState);
  const shortActiveSaleId = useRecoilValue(shortActiveSaleIdState);

  return (
    <section className="flex flex-col gap-6 rounded border border-[#F3D9B3] bg-[#FBF2E6] p-6 md:flex-row md:items-center md:gap-8">
      <div className="flex grow flex-col gap-6 md:flex-row md:items-center">
        <Checklist className="size-10 shrink-0 text-orange-600" />
        <div className="flex flex-col gap-1 text-gray-700">
          <h4 className="text-sm font-bold">
            {t(
              'invoice.invoice_locked.sale_ended_need_actions.sale_is_over_but'
            )}
          </h4>
          <p className="text-sm leading-5.5">
            {t(
              'invoice.invoice_locked.sale_ended_need_actions.billing_available_fulfilled_requirements'
            )}
          </p>
        </div>
      </div>
      <Button
        type="button"
        appearance="primary"
        onClick={() =>
          navigate(`/${brand?.id}/orders?sid=${shortActiveSaleId}`, {
            replace: true,
          })
        }
        className="shrink-0 self-start md:self-auto"
      >
        {t('invoice.invoice_locked.sale_ended_need_actions.show_orders')}
      </Button>
    </section>
  );
};
