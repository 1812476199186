import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatedCheck } from '../animated-check/animated-check';

type ModalChangePasswordSuccessProps = {
  setIsOpen: (isOpen: boolean) => void;
};

export const ModalChangePasswordSuccess = ({
  setIsOpen,
}: ModalChangePasswordSuccessProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => setIsOpen(false), 3000);
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <AnimatedCheck className="mb-6 size-32" />
      <div className="text-center text-2xl font-bold text-gray-900">
        {t('auth.change_password.password_changed')}
      </div>
    </div>
  );
};
