import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import * as Dialog from '@radix-ui/react-dialog';

import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../components/carousel';
import { track } from '../../utils/analytics';
import { VideoGallery } from './video-gallery';

const IMAGE_BUCKET_URL =
  import.meta.env.REACT_APP_STAGE === 'production'
    ? 'https://storage.googleapis.com/images.appchoose.io'
    : 'https://storage.googleapis.com/images.appchoose.co';

export const GettingStarted: React.FC = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const slides = [
    {
      preview: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-settings-preview.png`,
      title: t('home.tutorial.slide_1.title'),
      video_en: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-settings-en.mp4`,
      video_fr: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-settings-fr.mp4`,
    },
    {
      preview: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-orders-preview.png`,
      title: t('home.tutorial.slide_2.title'),
      video_en: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-orders-en.mp4`,
      video_fr: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-orders-fr.mp4`,
    },
    {
      preview: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-claim-preview.png`,
      title: t('home.tutorial.slide_3.title'),
      video_en: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-claim-en.mp4`,
      video_fr: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-claim-fr.mp4`,
    },
    {
      preview: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-stock-preview.png`,
      title: t('home.tutorial.slide_4.title'),
      video_en: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-stock-en.mp4`,
      video_fr: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-stock-fr.mp4`,
    },
    {
      preview: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-invoice-preview.png`,
      title: t('home.tutorial.slide_5.title'),
      video_en: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-invoice-en.mp4`,
      video_fr: `${IMAGE_BUCKET_URL}/brand/getting-started/getting-started-invoice-fr.mp4`,
    },
  ];

  return (
    <section className="rounded-lg border border-gray-100 p-6">
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-3">
          <div className="rounded border border-gray-100 bg-[#FBFBFB] p-1 text-gray-900">
            <Icon icon="play" className="size-6" />
          </div>
          <span className="text-sm font-semibold text-gray-900">
            {t('home.tutorial.getting_started')}
          </span>
        </div>
        <div>
          <Carousel
            setApi={setApi}
            opts={{
              align: 'start',
              duration: 20,
            }}
            className="flex w-full max-w-sm flex-col gap-4"
          >
            <CarouselContent>
              {slides.map((slide, index) => (
                <CarouselItem key={index}>
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      track('OpenModalGettingStarted', {});
                    }}
                    className="relative flex flex-col gap-4"
                  >
                    <div className="group relative overflow-hidden rounded border border-gray-300">
                      <img src={slide.preview} alt="" />
                      <div className="absolute inset-0 flex items-center justify-center rounded group-hover:bg-black/50">
                        <div className="inline-flex rounded-full bg-black/40 p-4 group-hover:bg-white/40">
                          <Icon
                            icon="playFilled"
                            className="size-6 text-white"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-3 top-3 rounded-[0.1875rem] border border-gray-300 bg-white px-2 py-0.5 text-sm text-gray-900">
                      2 min
                    </div>
                  </button>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="flex flex-row gap-2">
              <div className="flex flex-1 flex-col gap-2">
                <div className="font-bold text-gray-900">
                  {slides[current - 1]?.title}
                </div>
                <div className="text-sm leading-5.5 text-[#767A7C]">
                  {current}/{count}
                </div>
              </div>
              <div className="flex gap-3 self-start bg-white pt-3">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(false)}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-40 bg-black/60" />
          <Dialog.Content className="fixed inset-0 z-40 overflow-y-auto">
            <div
              className="absolute inset-0"
              onClick={() => setIsOpen(false)}
            ></div>
            <div className="flex min-h-full items-center justify-center">
              <VideoGallery
                slides={slides.map((slide, index) => ({
                  index: index,
                  src: i18n.language === 'fr' ? slide.video_fr : slide.video_en,
                }))}
                initialIndex={current}
              />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </section>
  );
};
