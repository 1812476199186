import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';

export type OnboardingStepWelcomeProps = {
  goToNextStep: () => void;
};

export const OnboardingStepWelcome: React.FC<OnboardingStepWelcomeProps> = ({
  goToNextStep,
}: OnboardingStepWelcomeProps) => {
  const { t } = useTranslation();

  return (
    <section className="w-full">
      <div className="mt-8 space-y-10 sm:mt-20">
        <h2 className="text-2xl font-bold sm:text-3.5xl">
          {t('onboarding.welcome.title')}
        </h2>

        <div className="space-y-4">
          <div className="flex flex-initial space-x-4 self-start rounded-lg border border-gray-300 p-6 pr-8">
            <Icon icon="clock" size="large" className="text-gray-700" />
            <div className="space-y-1">
              <p className="m-0 text-sm font-semibold text-gray-700">
                {t('onboarding.welcome.time_needed')}
              </p>
              <p className="m-0 text-sm leading-5.5 text-gray-700">
                {t('onboarding.welcome.about_x_minutes', {
                  minutes: '8',
                })}
              </p>
            </div>
          </div>
        </div>

        <Button size="large" type="submit" onClick={goToNextStep}>
          {t('start')}
        </Button>
      </div>
    </section>
  );
};
