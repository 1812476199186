import React from 'react';

import cn from '@appchoose/cn';

/**
 * The container component of the analytics card.
 */
const AnalyticsCard = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('rounded-lg border border-gray-100 p-4', className)}
      {...props}
    />
  );
});
AnalyticsCard.displayName = 'AnalyticsCard';

const AnalyticsCardContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('space-y-4 p-2', className)} {...props} />
  );
});
AnalyticsCardContent.displayName = 'AnalyticsCardContent';

/**
 * The label component of the analytics card. This component is used to display the label inside the container.
 */
const AnalyticsCardLabel = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return (
    <dt
      ref={ref}
      className={cn(
        'text-xs font-semibold uppercase tracking-wider text-gray-700',
        className
      )}
      {...props}
    />
  );
});
AnalyticsCardLabel.displayName = 'AnalyticsCardLabel';

/**
 * The value component of the analytics card. This component is used to display the main value inside the container.
 */
const AnalyticsCardValue = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'dd'>
>(({ className, ...props }, ref) => {
  return (
    <dd
      ref={ref}
      className={cn('text-3.5xl font-bold text-gray-900', className)}
      {...props}
    />
  );
});
AnalyticsCardValue.displayName = 'AnalyticsCardValue';

export {
  AnalyticsCard,
  AnalyticsCardContent,
  AnalyticsCardLabel,
  AnalyticsCardValue,
};
