import { StoreRegion } from './generated';

export type ChooseContact = {
  ids: string[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  avatar: string;
  role: ContactRole[];
  saleRegion: StoreRegion[];
  isActive: boolean;
};

export enum ContactRole {
  Sale = 'sale',
  Hr = 'hr',
  Ops = 'ops',
  Setup = 'setup',
}

// Copied from https://github.com/appchoose/react-meusnidus/blob/main/src/types/choose-contact.ts
export const contacts: ChooseContact[] = [
  {
    ids: ['floriane', 'flo'],
    firstName: 'Floriane',
    lastName: 'Guiniot',
    phoneNumber: '+33633413050',
    email: 'floriane@appchoose.io',
    avatar: 'floriane',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['jessica'],
    firstName: 'Jessica',
    lastName: 'Guyomard',
    phoneNumber: '+33695398869',
    email: 'jessica@appchoose.io',
    avatar: 'jessica',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['rebecca'],
    firstName: 'Rébecca',
    lastName: 'Cloarec',
    phoneNumber: '+33652347519',
    email: 'rebecca@appchoose.io',
    avatar: 'rebecca',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['juliette.act'],
    firstName: 'Juliette',
    lastName: 'Aucouturier',
    phoneNumber: '+33658019979',
    email: 'juliette.aucouturier@appchoose.io',
    avatar: 'juliette-act',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['alice'],
    firstName: 'Alice',
    lastName: 'Pelissier',
    phoneNumber: '+33660220711',
    email: 'alice@appchoose.io',
    avatar: 'alice',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['isadora'],
    firstName: 'Isadora',
    lastName: 'Mathian',
    phoneNumber: '+33622541053',
    email: 'isadora@appchoose.io',
    avatar: 'isadora',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['sarah'],
    firstName: 'Sarah',
    lastName: 'Gendreau',
    phoneNumber: '+33682305703',
    email: 'sarah@appchoose.io',
    avatar: 'sarah',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['claire.p'],
    firstName: 'Claire',
    lastName: 'Poulain',
    phoneNumber: '+33785541841',
    email: 'claire.p@appchoose.io',
    avatar: 'claire',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['clara'],
    firstName: 'Clara',
    lastName: 'Esposito',
    phoneNumber: '+33769305299',
    email: 'clara@appchoose.io',
    avatar: 'clara',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['alizé', 'alize'],
    firstName: 'Alizé',
    lastName: 'Pigné',
    phoneNumber: '+33638126186',
    email: 'alize@appchoose.io',
    avatar: 'alize',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['alexandra'],
    firstName: 'Alexandra',
    lastName: 'Ceddia',
    phoneNumber: '+33658722970',
    email: 'alexandra@appchoose.io',
    avatar: 'alexandra',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['juliette'],
    firstName: 'Juliette',
    lastName: 'Afanassieff',
    phoneNumber: '+33623485786',
    email: 'juliette@appchoose.io',
    avatar: 'juliette',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['camille', 'camille.s'],
    firstName: 'Camille',
    lastName: 'Sauvaud',
    phoneNumber: '+33686309234',
    email: 'camille.s@appchoose.io',
    avatar: 'camille',
    role: [ContactRole.Hr],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['marion', 'marion ansel'],
    firstName: 'Marion',
    lastName: 'Ansel',
    phoneNumber: '+33609606196',
    email: 'marion@appchoose.io',
    avatar: 'marion',
    role: [ContactRole.Sale, ContactRole.Ops, ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['hortense'],
    firstName: 'Hortense',
    lastName: 'Tharreau',
    phoneNumber: '+33631261212',
    email: 'hortense@appchoose.io',
    avatar: 'hortense',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['anthony', 'anthony.gatti'],
    firstName: 'Anthony',
    lastName: 'Gatti',
    phoneNumber: '+33780912793',
    email: 'anthony.gatti@appchoose.io',
    avatar: 'anthony',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['lisa'],
    firstName: 'Lisa',
    lastName: 'Golsenne',
    phoneNumber: '+33667733388',
    email: 'lisa@appchoose.io',
    avatar: 'lisa',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['margaux jullian'],
    firstName: 'Margaux',
    lastName: 'Jullian',
    phoneNumber: '+33664313932',
    email: 'margaux@appchoose.io',
    avatar: 'Margaux-Jullian',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['mélissa imrek'],
    firstName: 'Mélissa',
    lastName: 'Imrek',
    phoneNumber: '+33669662860',
    email: 'melissa@appchoose.io',
    avatar: 'Melissa-Imrek',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['gaëlle leroux'],
    firstName: 'Gaëlle',
    lastName: 'Leroux',
    phoneNumber: '+33602354557',
    email: 'gaelle@appchoose.io',
    avatar: 'Gaelle-Leroux',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['léa perazio--elleon'],
    firstName: 'Léa',
    lastName: 'Perazio Elleon',
    phoneNumber: '',
    email: 'lea@appchoose.io',
    avatar: 'Lea-Perazio',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['alexandre graulle'],
    firstName: 'Alexandre',
    lastName: 'Graulle',
    phoneNumber: '',
    email: 'alexandre.g@appchoose.io',
    avatar: 'Alexandre-Graulle',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['angie thomas'],
    firstName: 'Angie',
    lastName: 'Thomas',
    phoneNumber: '+33699923918',
    email: 'angie@appchoose.io',
    avatar: 'Angie-Thomas',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['thuy an le'],
    firstName: 'Thuy An',
    lastName: 'Le',
    phoneNumber: '+33650847690',
    email: 'thuyan@appchoose.io',
    avatar: 'Thuy-An-Le',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['pauline garcin'],
    firstName: 'Pauline',
    lastName: 'Garcin',
    phoneNumber: '',
    email: 'pauline.g@appchoose.io',
    avatar: 'Pauline-Garcin',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['charlotte'],
    firstName: 'Charlotte',
    lastName: 'Simonnet',
    phoneNumber: '',
    email: 'charlotte@appchoose.io',
    avatar: 'Charlotte-Simonnet',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['clemence.c'],
    firstName: 'Clémence',
    lastName: 'Chevre',
    phoneNumber: '+33627912802',
    email: 'clemence.c@appchoose.io',
    avatar: 'Clemence-Chevre',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['louis.h'],
    firstName: 'Louis',
    lastName: 'Heraud',
    phoneNumber: '+33760073245',
    email: 'louis.h@appchoose.io',
    avatar: 'Louis-Heraud',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['gael'],
    firstName: 'Gaël',
    lastName: 'Treillard',
    phoneNumber: '',
    email: 'gael@appchoose.io',
    avatar: 'Gael-Treillard',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['manon.l'],
    firstName: 'Manon',
    lastName: 'Leclercq',
    phoneNumber: '',
    email: 'manon.l@appchoose.io',
    avatar: 'Manon-Leclercq',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['hsiao-anne'],
    firstName: 'Hsiao-Anne',
    lastName: 'Le Bot',
    phoneNumber: '',
    email: 'hsiao-anne@appchoose.io',
    avatar: 'HsiaoAnne-LeBot',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['anne-stéphanie dupin'],
    firstName: 'Anne-Stéphanie',
    lastName: 'Dupin',
    phoneNumber: '',
    email: 'anne-stephanie@appchoose.io',
    avatar: 'AnneStephanie-Dupin',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['perrine gangloff'],
    firstName: 'Perrine',
    lastName: 'Gangloff',
    phoneNumber: '',
    email: 'perrine@appchoose.io',
    avatar: 'Perrine-Gangloff',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['calixta henrion'],
    firstName: 'Calixta',
    lastName: 'Henrion',
    phoneNumber: '',
    email: 'calixta@appchoose.io',
    avatar: 'Calixta-Henrion',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['maura'],
    firstName: 'Maura',
    lastName: 'Sheedy',
    phoneNumber: '',
    email: 'maura@appchoose.io',
    avatar: 'Maura-Sheedy',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['vincent.c'],
    firstName: 'Vincent',
    lastName: 'Collet',
    phoneNumber: '',
    email: 'vincent.c@appchoose.io',
    avatar: 'Vincent-Collet',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['camille collinet'],
    firstName: 'Camille',
    lastName: 'Collinet',
    phoneNumber: '',
    email: 'camille@appchoose.io',
    avatar: 'Camille-Collinet',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['juliette martinez'],
    firstName: 'Juliette',
    lastName: 'Martinez',
    phoneNumber: '',
    email: 'juliette.m@appchoose.io',
    avatar: 'Juliette-Martinez',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['chloe'],
    firstName: 'Chloé',
    lastName: 'Huchet',
    phoneNumber: '',
    email: 'chloe@appchoose.io',
    avatar: 'Chloe-Huchet',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['gabrielle bertrand'],
    firstName: 'Gabrielle',
    lastName: 'Bertrand',
    phoneNumber: '',
    email: 'gabrielle@appchoose.io',
    avatar: 'Gabrielle-Bertrand',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['ines'],
    firstName: 'Inès',
    lastName: 'Latrèche',
    phoneNumber: '',
    email: 'ines@appchoose.io',
    avatar: 'Ines-Latreche',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['abir'],
    firstName: 'Abir',
    lastName: 'Ben Thayer',
    phoneNumber: '',
    email: 'abir@appchoose.io',
    avatar: 'AbirBen-Thayer',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['philippine'],
    firstName: 'Philippine',
    lastName: 'Leblanc',
    phoneNumber: '',
    email: 'philippine@appchoose.io',
    avatar: 'Philippine-Leblanc',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['lucie.h'],
    firstName: 'Lucie',
    lastName: 'Heraud',
    phoneNumber: '',
    email: 'lucie.h@appchoose.io',
    avatar: 'Lucie-Heraud',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['elisa'],
    firstName: 'Elisa',
    lastName: 'Richou',
    phoneNumber: '',
    email: 'elisa@appchoose.io',
    avatar: 'Elisa-Richou',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['romane decrept'],
    firstName: 'Romane',
    lastName: 'Decrept',
    phoneNumber: '',
    email: 'romane@appchoose.io',
    avatar: 'Romane-Decrept',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['claire'],
    firstName: 'Claire',
    lastName: 'Colling',
    phoneNumber: '0660428006',
    email: 'claire@appchoose.io',
    avatar: 'Claire-Colling',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['pierre'],
    firstName: 'Pierre',
    lastName: 'Chereau',
    phoneNumber: '',
    email: 'pierre@appchoose.io',
    avatar: 'Pierre-Chereau',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['julia'],
    firstName: 'Julia',
    lastName: 'Gauthier',
    phoneNumber: '',
    email: 'julia@appchoose.io',
    avatar: 'Julia-Gauthier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['mina belasri'],
    firstName: 'Mina',
    lastName: 'Belasri',
    phoneNumber: '',
    email: 'mina@appchoose.io',
    avatar: 'Mina-Belasri',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['jordan kreindler'],
    firstName: 'Jordan',
    lastName: 'Kreindler',
    phoneNumber: '',
    email: 'jordan.kreindler@appchoose.io',
    avatar: 'Jordan-Kreindler',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['clémence bouras'],
    firstName: 'Clémence',
    lastName: 'Bouras',
    phoneNumber: '',
    email: 'clemence.b@appchoose.io',
    avatar: 'Clemence-Bouras',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['philippine.g'],
    firstName: 'Philippine',
    lastName: 'Gonord',
    phoneNumber: '',
    email: 'philippine.g@appchoose.io',
    avatar: 'Philippine-Gonord',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['camille villers'],
    firstName: 'Camille',
    lastName: 'Villers',
    phoneNumber: '',
    email: 'camille.v@appchoose.io',
    avatar: 'Camille-Villers',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['marie mateuf'],
    firstName: 'Marie',
    lastName: 'Mateuf',
    phoneNumber: '',
    email: 'marie.m@appchoose.io',
    avatar: 'Marie-Mateuf',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['nicole mathai'],
    firstName: 'Nicole',
    lastName: 'Mathai',
    phoneNumber: '',
    email: 'nicole@appchoose.io',
    avatar: 'Nicole-Mathai',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['maeva'],
    firstName: 'Maeva',
    lastName: 'Renaudin',
    phoneNumber: '',
    email: 'maeva@appchoose.io',
    avatar: 'Maeva-Renaudin',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['isabel'],
    firstName: 'Isabel',
    lastName: 'Montalvan',
    phoneNumber: '',
    email: 'isabel@appchoose.io',
    avatar: 'Isabel-Montalvan',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['pierre brusco'],
    firstName: 'Pierre',
    lastName: 'Brusco',
    phoneNumber: '',
    email: 'pierre.b@appchoose.io',
    avatar: 'Pierre-Brusco',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['su htoo mon'],
    firstName: 'Su',
    lastName: 'Htoo Mon',
    phoneNumber: '',
    email: 'su@appchoose.io',
    avatar: 'Su-Htoo-Mon',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['charlotte brajou'],
    firstName: 'Charlotte',
    lastName: 'Brajou',
    phoneNumber: '',
    email: 'charlotte.brajou@appchoose.io',
    avatar: 'Charlotte-Brajou',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['charlotte.ollitrault'],
    firstName: 'Charlotte',
    lastName: 'Ollitrault',
    phoneNumber: '',
    email: 'charlotte.ollitrault@appchoose.io',
    avatar: 'Charlotte-Ollitrault',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['andrea.m'],
    firstName: 'Andréa',
    lastName: 'Mercadié',
    phoneNumber: '',
    email: 'andrea.m@appchoose.io',
    avatar: 'Andrea-Mercadie',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['anne-claire.b'],
    firstName: 'Anne-Claire',
    lastName: 'Barthelemy',
    phoneNumber: '',
    email: 'anne-claire.b@appchoose.io',
    avatar: 'Anne-Claire-Barthelemy',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['apolline.g'],
    firstName: 'Apolline',
    lastName: 'Gibon',
    phoneNumber: '',
    email: 'apolline.g@appchoose.io',
    avatar: 'Apolline-Gibon',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['charlotte.bigotte', 'charlotte.b'],
    firstName: 'Charlotte',
    lastName: 'Bigotte',
    phoneNumber: '',
    email: 'charlotte.bigotte@appchoose.io',
    avatar: 'Charlotte-Bigotte',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['mathilde.u'],
    firstName: 'Mathilde',
    lastName: 'Uhlhorn',
    phoneNumber: '',
    email: 'mathilde.u@appchoose.io',
    avatar: 'Mathilde-Uhlhorn',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['lucie noblanc'],
    firstName: 'Lucie',
    lastName: 'Noblanc',
    phoneNumber: '',
    email: 'lucie.noblanc@appchoose.io',
    avatar: 'Lucie-Noblanc',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['noemie.b'],
    firstName: 'Noémie',
    lastName: 'Babinot',
    phoneNumber: '',
    email: 'noemie.b@appchoose.io',
    avatar: 'Noemie-Babinot',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['capucine.d'],
    firstName: 'Capucine',
    lastName: 'Dumans',
    phoneNumber: '',
    email: 'capucine.d@appchoose.io',
    avatar: 'Capucine-Dumans',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['midori.harvey'],
    firstName: 'Midori',
    lastName: 'Harvey',
    phoneNumber: '',
    email: 'midori.harvey@appchoose.io',
    avatar: 'Midori-Harvey',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['taylor.brohrer'],
    firstName: 'Taylor',
    lastName: 'Brohrer',
    phoneNumber: '',
    email: 'taylor@appchoose.io',
    avatar: 'Taylor-Brohrer',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['lea.s'],
    firstName: 'Léa',
    lastName: 'Sellenet',
    phoneNumber: '',
    email: 'lea.sellenet@appchoose.io',
    avatar: 'Lea-Sellenet',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['clemence.cavelier'],
    firstName: 'Clémence',
    lastName: 'Cavelier',
    phoneNumber: '',
    email: 'clemence.c@appchoose.io',
    avatar: 'Clemence-Cavelier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['clara-lou'],
    firstName: 'Clara-Lou',
    lastName: 'Da Fonseca',
    phoneNumber: '',
    email: 'clara-lou@appchoose.io',
    avatar: 'ClaraLou-DaFonsecaCoelho',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['mathilde.r'],
    firstName: 'Mathilde',
    lastName: 'Ramalho',
    phoneNumber: '',
    email: 'mathilde.r@appchoose.io',
    avatar: 'Mathilde-Ramalho',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['ximena chilet buitrón'],
    firstName: 'Ximena',
    lastName: 'Chilet Buitrón',
    phoneNumber: '',
    email: 'ximena@appchoose.io',
    avatar: 'Ximena-Chilet',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['magali velasco'],
    firstName: 'Magali',
    lastName: 'Velasco',
    phoneNumber: '',
    email: 'magali.v@appchoose.io',
    avatar: 'Magali-Velasco',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: false,
  },
  {
    ids: ['annelise.quelard'],
    firstName: 'Anne-Lise',
    lastName: 'Quelard',
    phoneNumber: '',
    email: 'annelise.quelard@appchoose.io',
    avatar: 'AnneLise-Quelard',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['alexandre.bellot'],
    firstName: 'Alexandre',
    lastName: 'Bellot',
    phoneNumber: '',
    email: 'alexandre.bellot@appchoose.io',
    avatar: 'Alexandre-Bellot',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['jade.d'],
    firstName: 'Jade',
    lastName: 'Devoucoux',
    phoneNumber: '',
    email: 'jade.d@appchoose.io',
    avatar: 'Jade-Devoucoux',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['alizee.aventurier'],
    firstName: 'Alizée',
    lastName: 'Aventurier',
    phoneNumber: '',
    email: 'alizee.aventurier@appchoose.io',
    avatar: 'Alizee-Aventurier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: false,
  },
  {
    ids: ['pauline.vivier'],
    firstName: 'Pauline',
    lastName: 'Vivier',
    phoneNumber: '',
    email: 'pauline.vivier@appchoose.io',
    avatar: 'Pauline-Vivier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['justine.hueber'],
    firstName: 'Justine',
    lastName: 'Hueber',
    phoneNumber: '',
    email: 'justine.hueber@appchoose.io',
    avatar: 'Justine-Hueber',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['jonas.texier'],
    firstName: 'Jonas',
    lastName: 'Texier',
    phoneNumber: '',
    email: 'jonas.texier@appchoose.io',
    avatar: 'Jonas-Texier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['ambre dumont sautter'],
    firstName: 'Ambre',
    lastName: 'Dumont Sautter',
    phoneNumber: '',
    email: 'ambre.dumont-sautter@appchoose.io',
    avatar: 'Ambre-Dumont-Sautter',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['morgane micheau'],
    firstName: 'Morgane',
    lastName: 'Micheau',
    phoneNumber: '',
    email: 'morgane.micheau@appchoose.io',
    avatar: 'Morgane-Micheau',
    role: [ContactRole.Setup],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['isham.dramsy'],
    firstName: 'Isham',
    lastName: 'Dramsy',
    phoneNumber: '',
    email: 'isham@appchoose.io',
    avatar: 'Isham-Dramsy',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['emma.gautier'],
    firstName: 'Emma',
    lastName: 'Gautier',
    phoneNumber: '',
    email: 'emma.gautier@appchoose.io',
    avatar: 'Emma-Gautier',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['ahmed.zinelabidine'],
    firstName: 'Ahmed',
    lastName: 'Zinelabidine',
    phoneNumber: '',
    email: 'ahmed.zinelabidine@appchoose.io',
    avatar: 'Ahmed-Zinelabidine',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },
  {
    ids: ['lea.simonin'],
    firstName: 'Léa',
    lastName: 'Simonin',
    phoneNumber: '',
    email: 'lea.simonin@appchoose.io',
    avatar: 'Lea-Simonin',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['aurelia.cuny-hubert'],
    firstName: 'Aurélia',
    lastName: 'Cuny-Hubert',
    phoneNumber: '',
    email: 'aurelia.cuny-hubert@appchoose.io',
    avatar: 'Aurelia-CunyHubert',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
  {
    ids: ['theo.delefortrie'],
    firstName: 'Théo',
    lastName: 'Delefortrie',
    phoneNumber: '',
    email: 'theo.delefortrie@appchoose.io',
    avatar: 'Theo-Delefortrie',
    role: [ContactRole.Ops],
    saleRegion: [StoreRegion.Fr, StoreRegion.Us],
    isActive: true,
  },

  {
    ids: ['stefania.dupont'],
    firstName: 'Stefania',
    lastName: 'Dupont',
    phoneNumber: '',
    email: 'stefania.dupont@appchoose.io',
    avatar: 'Stefania-Dupont',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },

  {
    ids: ['leyla.paolucci'],
    firstName: 'Leyla',
    lastName: 'Paolucci',
    phoneNumber: '',
    email: 'leyla.paolucci@appchoose.io',
    avatar: 'Leyla-Paolucci',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },

  {
    ids: ['ilana.bismuth'],
    firstName: 'Ilana',
    lastName: 'Bismuth',
    phoneNumber: '',
    email: 'ilana.bismuth@appchoose.io',
    avatar: 'Ilana-Bismuth',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },

  {
    ids: ['lou.foucher'],
    firstName: 'Lou',
    lastName: 'Foucher',
    phoneNumber: '',
    email: 'lou.foucher@appchoose.io',
    avatar: 'Lou-Foucher',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },

  {
    ids: ['jane.guilloteau'],
    firstName: 'Jane',
    lastName: 'Guilloteau',
    phoneNumber: '',
    email: 'jane.guilloteau@appchoose.io',
    avatar: 'Jane-Guilloteau',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },

  {
    ids: ['emma.benaud'],
    firstName: 'Emma',
    lastName: 'Benaud',
    phoneNumber: '',
    email: 'emma.benaud@appchoose.io',
    avatar: 'Emma-Benaud',
    role: [ContactRole.Sale],
    saleRegion: [StoreRegion.Fr],
    isActive: true,
  },
];

export const amDefaultContact = contacts.find((contact) =>
  contact.ids.includes('abir')
)!;

export const saleDefaultContact = contacts.find((contact) =>
  contact.ids.includes('floriane')
)!;

export const logisticsManagerDefaultContact = contacts.find((contact) =>
  contact.ids.includes('hortense')
)!;

export const findContact = (
  contactName: string | undefined,
  defaultContact: ChooseContact
) => {
  const name = (contactName ?? '').toLowerCase();
  return (
    contacts.find((contact) => contact.ids.includes(name)) ?? defaultContact
  );
};
