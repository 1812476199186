import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../components/carousel';

export type Slide = {
  index: number;
  src: string;
};

type VideoGalleryProps = {
  initialIndex?: number;
  slides: Slide[];
};

export const VideoGallery: React.FC<VideoGalleryProps> = ({
  initialIndex,
  slides,
}) => {
  return (
    <Carousel
      opts={{
        startIndex: initialIndex,
        align: 'start',
        duration: 20,
      }}
      className="relative max-w-[57rem]"
    >
      <CarouselContent>
        {slides.map((slide, index) => (
          <CarouselItem
            key={index}
            className="flex items-center justify-center"
          >
            <div className="relative flex flex-col gap-4">
              <video src={slide.src} controls playsInline />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>

      <CarouselPrevious className="absolute -left-20 top-1/2 -translate-y-1/2 border-gray-900 bg-gray-900 text-white focus:bg-gray-900 focus-visible:bg-gray-900 disabled:border-gray-300 disabled:bg-gray-300 disabled:text-white" />
      <CarouselNext className="absolute -right-20 top-1/2 -translate-y-1/2 border-gray-900 bg-gray-900 text-white focus:bg-gray-900 focus-visible:bg-gray-900 disabled:border-gray-300 disabled:bg-gray-300 disabled:text-white" />
    </Carousel>
  );
};
