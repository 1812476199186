type ChecklistProps = React.ComponentPropsWithoutRef<'svg'>;

export const Checklist: React.FC<ChecklistProps> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 20L12.5 21.5L16 18"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20H30"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M11 10L12.5 11.5L16 8"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 10H30"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M20 31H30"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <circle cx="13" cy="31" r="2.3" stroke="currentColor" strokeWidth="1.4" />
      <rect
        x="6.7"
        y="2.7"
        width="27.6"
        height="34.6"
        rx="1.3"
        stroke="currentColor"
        strokeWidth="1.4"
      />
    </svg>
  );
};
