import { useTranslation } from 'react-i18next';
import { useCopyToClipboard, useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

import { Send } from '../../components/icons/send';
import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { track } from '../../utils/analytics';
import { getInvoiceEmail } from '../../utils/utils';
import { useBilling } from './use-billing';

export const InvoiceLockedUnlockExceptionalDownPayment: React.FC = () => {
  const { t } = useTranslation();
  const { data: billing } = useBilling();
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const brand = useRecoilValue(brandState);

  useUpdateEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(t('clipboard.email_copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState]);

  if (
    !billing?.down_payment?.__typename ||
    billing.down_payment.__typename !== 'DownPaymentExceptional'
  ) {
    return null;
  }

  const invoiceEmail = getInvoiceEmail(brand?.store ?? StoreRegion.Fr);

  return (
    <div className="flex flex-col items-center gap-6 rounded border border-gray-100 bg-[#FBFBFB] p-6 md:flex-row">
      <Send className="size-10 shrink-0 text-gray-900" />
      <div className="grow whitespace-pre-line text-sm font-semibold">
        {t(
          'invoice.invoice_locked.unlock_exceptional_down_payment.send_invoice_according_to_terms'
        )}
      </div>
      <button
        type="button"
        className="flex place-content-center items-center gap-1 rounded-lg border border-gray-100 bg-[#FBFBFB] px-4 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]"
        onClick={() => {
          copyToClipboard(invoiceEmail);
          track('CopyToClipboard', {
            label: 'billing_email',
          });
        }}
      >
        <span>{invoiceEmail}</span>
        <Icon icon="copy" className="size-3.5" />
      </button>
    </div>
  );
};
